import $ from 'jquery';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ForgeIcon,
  ForgeMenu,
  ForgeIconButton
} from '@tylertech/forge-react';
import { toast } from 'react-toastify';

import { shouldShowEmbedLinkInKabob } from 'common/config/customerConfiguration';
import { getCardId, getLink, getMetricUrl } from 'pages/embed/helper';
import { embedCopyMessage, embedCopyErrorMessage } from 'helpers/toastMessages';
import DeleteConfirmModal from 'pages/dashboard/components/DeleteConfirmModal';
import { COLLECTION_CARD_DELETE_MESSAGE } from 'appConstants';
import { useDeleteMetricCardFromCollection } from '../../useDeleteMetricCardFromCollection';
import { useSelector } from 'react-redux';

function MetricHeader(props) {
  const { metricCardEntry, headerTitle, onDeleteMetricCard, setIsCardsLoading } = props;
  const { templateEntry, viewEntry, userCardEntry } = metricCardEntry;
  const cardId = getCardId(templateEntry, viewEntry);
  const collectionId = useSelector(state => _.get(state, 'dashboard.currentCollectionId', ''));
  const [isDeleteClicked, setIsDeleteClicked ] = useState(false);
  const { deleteCardData, isLoading } = useDeleteMetricCardFromCollection();

  const handleEmbedClick = () => {
    const metricUrl = getMetricUrl(cardId);
    const link = getLink(cardId, metricUrl);

    // https://stackoverflow.com/questions/22581345/click-button-copy-to-clipboard-using-jquery
    try {
      const $inputElement = $("<input>");
      $("body").append($inputElement);
      $inputElement.val(link).select();
      document.execCommand('copy');
      $inputElement.remove();
      toast.success(embedCopyMessage);
    } catch (err) {
      toast.error(embedCopyErrorMessage);
    }
  };

  const handleCardDelete = async () => {
    const cardEntryId = userCardEntry.id;
    setIsCardsLoading(true);
    await deleteCardData(collectionId, cardEntryId);
    onDeleteMetricCard();
    setIsCardsLoading(isLoading);
  };

  const handleMenuSelect = ({ detail }) => {
    const { value } = detail;
    if (value === 'embed') {
      handleEmbedClick();
    } else if (value === 'delete') {
      setIsDeleteClicked(true);
    }
  };

  const renderMenus = () => {
    const showEmbedLinkInKabob = shouldShowEmbedLinkInKabob();
    let options = []

    if (showEmbedLinkInKabob) {
      options.push({ value: 'embed', label: 'Embed' });
    }
    if (options.length === 0) {
      return null;
    }

    return (
      <ForgeMenu
        placement='bottom-start'
        key={'library-card' + cardId}
        options={options}
        on-forge-menu-select={handleMenuSelect}>
        <ForgeIconButton type="button">
          <button type="button" aria-label="Card menu">
            <ForgeIcon name='more_horiz' className="more-icon" />
          </button>
        </ForgeIconButton>
      </ForgeMenu>
    );
  }

  return (
    <header className="forge-dialog__header">
      <div className='forge-typography--caption'>{headerTitle}</div>
      <span className="cursor-pointer more-info forge-popup-host">
        { renderMenus() }
        {isDeleteClicked &&
          <DeleteConfirmModal
            name='Remove'
            title="Remove tile from collection?"
            showNormalButton={true}
            showButtons={false}
            confirmButtonName="Remove"
            onCancelConfirm={() => setIsDeleteClicked(false)}
            message={COLLECTION_CARD_DELETE_MESSAGE}
            // showMenuItems={this.handleClickMenuItems}
            onDeleteConfirm={handleCardDelete}
          />
        }
      </span>
    </header>
  );
}

MetricHeader.propTypes = {
  metricCardEntry: PropTypes.object,
  headerTitle: PropTypes.string,
  setIsCardsLoading: PropTypes.func,
  onDeleteMetricCard: PropTypes.func
}

export default MetricHeader
