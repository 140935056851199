import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Collections from './components/Collections/Collections';
import CardList from './components/CardList/CardList';
import {
  getCardId,
  getCollectionCardEntries,
  hasNoAccessToCollection
} from './components/Collections/collectionHelper';
import { createCard, getCollectionCards, updateCardsOrder } from 'common/api/collectionApi';
import {
  setCurrentCollectionId,
  setDefaultTemplateEntry,
  setBellerophonCollectionTagId,
  setIsManageCollection,
  setIsManageMetricLibrary
} from 'actions/dashboardActions';
import DragAndDrop from "common/components/DragAndDrop";

import { updateCurrentBookmark } from 'actions/bookmarkActions';
import {
  COLLECTION_ID,
  COLLECTION_TYPES,
  CARD_TYPES,
  EMAIL_STRATEGY_TYPES
} from 'appConstants';

import { getBookMarks, acceptAllBookmark } from 'common/api/bookmarksApi';
import { updateCurrentCollectionId } from 'common/api/commonApi';
import LoadingSpinner from 'common/components/LoadingSpinner';

import { removePolygonFilter } from 'actions/mapOptionsActions';
import {
  updateGlobalFilter,
  updateAllMetricsFilter,
  updateCollectionDateFilters
} from 'actions/commonFiltersActions';
import { updateInsightsId } from 'actions/insightActions';
import { setOverwriteCollectionFilter } from 'actions/dashboardActions';

import {
  getAllMetricEntries,
  getCustomTagMetricEntries,
} from 'helpers/templateHelper';
import {
  commonFiltersPropTypes,
  userPropTypes,
  templateEntriesPropTypes,
} from 'common/propTypes';
import {
  getVisibleBookmarks,
  isMyViews,
  getTemplateAndViewEntryFromBookmark
} from 'pages/dashboard/components/CardList/cardHelper';
import DummyCard from './components/CardList/DummyCard';
import { getCardEntriesWithRadarAndMapExplorerCard } from './components/RadarCards/helper';
import { isPrivate } from "common/config/customerConfiguration";
import { PageScrollHandlerWrapper } from 'common/components/Hoc/PageScrollHandlerWrapper';
import CollaborateCollectionCreatedAt from './components/ManageCollection/CollaborateCollectionCreatedAt';
import MetricDrawer from './components/Collections/MetricLibrary/MetricDrawer';
import DropTargetCard from './DropTargetCard';
import { copyCardInCollection } from 'actions/userCollectionsActions';
import { isNewMetricLabelCard } from 'helpers/metricNewLabelHelper';
import {
  canUserRestrictCollections,
  isCollaborateCollection,
  isCollectionViewAccess,
  isRestrictedCollection,
} from './components/ManageCollection/collaboratorHelper';
import { metricCopiedErrorMessage, metricCopiedMessage } from 'helpers/toastMessages';
import OnboardWizard from './components/OnboardWizard/OnboardWizard';
import AccessDenied from 'pages/AccessDenied/AccessDenied';

class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      componentUpdateCounter: 1,
      bookmarks: [],
      allBookmarks: [],
      isLoading: false,
      isCardsLoading: false,
      isCollectionCardLoading: false,
      collections: [],
      currentCollection: {},
      cardEntries: [],
      userCards: [],
      collectionFilters: [],
      newLabelCards: [],
      bookmarkCardsFromCollections: [],
      reloadCollection: false
    };
    this.abortFetchController = new AbortController();
    this.abortCardsApi = new AbortController();
    this.abortCopyCardsApi = new AbortController();
  }

  componentDidMount() {
    const { dispatchRemovePolygonFilter, dispatchRemoveInsightsId } = this.props;
    this.fetchBookMarks();

    dispatchRemovePolygonFilter();
    $('body').addClass('disable-plotly-chart-default-drag');
    $('body').addClass('overview-page');
    dispatchRemoveInsightsId();
  }

  componentWillUnmount() {
    $('body').removeClass('disable-plotly-chart-default-drag');
    $('body').removeClass('overview-page');
  }

  componentDidUpdate(prevProps) {
    const { currentCollectionId } = this.props;
    const { componentUpdateCounter } = this.state;
    if (!_.isEqual(prevProps.currentCollectionId, currentCollectionId)) {
      if (isMyViews(currentCollectionId) && componentUpdateCounter == 1) {
        this.fetchBookMarks();
      }
    }
  }

  getViewBookmarks = (response, isBookmarkView = false) => {
    const { bookmarkCardsFromCollections } = this.state;
    const bookmarkIds = _.map(bookmarkCardsFromCollections, 'bookmark_id');

    return _.chain(response).
      filter((bookmark) => {
        const isNeverEmailStrategy = _.get(bookmark, 'email_strategy') === EMAIL_STRATEGY_TYPES.NEVER;
        const isManageView = _.get(bookmark, 'is_manage_view', false);
        if (isBookmarkView && isManageView) {
          return false;
        }
        if (_.includes(bookmarkIds, _.toString(bookmark.id))) {
          return (isNeverEmailStrategy && bookmark.is_shared_accepted);
        } else {
          return (isNeverEmailStrategy);
        }
      }).
      map(bookmark => {
        // we need templateEntry and viewEntry for display
        // additional and independent DateFilters in bookmark page.
        const { templateEntry, viewEntry } = getTemplateAndViewEntryFromBookmark(bookmark);
        return { ...bookmark, templateEntry, viewEntry };
      }).
      value();
  }

  getCurrentCardType = () => {
    const { currentCollection } = this.state;
    let cardType;

    if (isMyViews(currentCollection)) {
      cardType = CARD_TYPES.BOOKMARK;
    } else {
      cardType = CARD_TYPES.TEMPLATE;
    }
    return cardType;
  }

  handleAcceptAllBookmark = () => {
    const { currentUser } = this.props;
    if (_.isEmpty(currentUser)) {
      return;
    }
    this.setState({ isLoading: true });
    const { componentUpdateCounter } = this.state;
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    acceptAllBookmark({ signal: this.abortFetchController.signal })
      .then((response) => response.json())
      .then((response) => {
        const visibleBookmarks = getVisibleBookmarks(response);

        this.setState({
          isLoading: false,
          bookmarks: this.getViewBookmarks(visibleBookmarks),
          allBookmarks: response,
          componentUpdateCounter: componentUpdateCounter + 1,
        }, () => {
          this.fetchCollectionCards();
        });
      })
      .catch((error) => {
        console.log('Error on accept all bookmarks', error);
        this.setState({
          isLoading: false,
          bookmarks: [],
          allBookmarks: [],
          componentUpdateCounter: componentUpdateCounter + 1,
        });
      });
  };

  fetchBookMarks = () => {
    this.setState({ isLoading: true });
    const { componentUpdateCounter, currentCollection } = this.state;
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();

    getBookMarks({ signal: this.abortFetchController.signal })
      .then((response) => response.json())
      .then((response) => {
        const visibleBookmarks = getVisibleBookmarks(response);
        const bookmarks = this.getViewBookmarks(visibleBookmarks);

        this.setState(
          {
            isLoading: false,
            isCollectionCardLoading: false,
            bookmarks,
            allBookmarks: visibleBookmarks,
            componentUpdateCounter: componentUpdateCounter + 1,
          },
          () => {
            if (!_.isEmpty(currentCollection)) {
              this.handleCollectionChange(currentCollection);
            }
            this.fetchCollectionCards();
          }
        );
      })
      .catch((error) => {
        console.log('Error on fetching bookmarks', error);
        this.setState({
          isLoading: false,
          bookmarks: [],
          allBookmarks: [],
          componentUpdateCounter: componentUpdateCounter + 1,
        });
      });
  };

  fetchCollectionCards = () => {
    const { currentCollection, bookmarks, allBookmarks, currentUser } = this.state;
    const { currentCollectionId, templateEntries, dispatchDefaultTemplateEntry } = this.props;
    let cardEntries;
    this.abortCardsApi.abort();
    this.abortCardsApi = new AbortController();
    this.setState({ isCardsLoading: true });

    if (isMyViews(currentCollection)) {
      cardEntries = this.getViewBookmarks(bookmarks, true);
      this.setState({ cardEntries, isCardsLoading: false });
    } else if (_.get(currentCollection, 'type') != COLLECTION_TYPES.USER) {
      let isPrivateApp = false;
      if (currentCollectionId == COLLECTION_ID.ALL_METRICS) {
        isPrivateApp = true;
        cardEntries = getAllMetricEntries(templateEntries);
      } else {
        isPrivateApp = (isPrivate() || !_.isEmpty(currentUser));
        cardEntries = getCustomTagMetricEntries(
          templateEntries,
          _.get(currentCollection, 'bellerophon_tag_id', currentCollection['tag_id'])
        );
      }

      this.setState({
        cardEntries: getCardEntriesWithRadarAndMapExplorerCard(
          cardEntries, isPrivateApp), isCardsLoading: false
      }, () => dispatchDefaultTemplateEntry(_.first(cardEntries)));
    } else {
      getCollectionCards(currentCollection.id, this.abortCardsApi)
        .then((response) => {
          const collectionCardEntries = getCollectionCardEntries(response, templateEntries, allBookmarks);
          this.setState({
            isCardsLoading: false,
            userCards: response,
            cardEntries: collectionCardEntries
          }, () => dispatchDefaultTemplateEntry(_.first(collectionCardEntries)));
        });
    }
  };

  onUpdateCollection = (collection) => {
    this.setState({ currentCollection: collection });
  }

  handleCollectionChange = (collection, newLabelCards, bookmarkCardsFromCollections = []) => {
    const {
      dispatchSetCurrentCollectionId,
      dispatchSetBellerophonCollectionTagId,
      dispatchCollectionDateFilters,
      dispatchSetIsManageCollection
    } = this.props;
    const collectionId = _.get(collection, 'id');
    const collectionTagId = _.get(collection, 'bellerophon_tag_id') || _.get(collection, 'tag_id');
    const dateFilters = _.get(collection, 'dateFilters');
    const { currentCollection } = this.state;
    const updatedNewLabelCards = _.isNil(newLabelCards) ? this.state.newLabelCards : newLabelCards;
    const newBookmarkCards = _.isEmpty(bookmarkCardsFromCollections) ?
      this.state.bookmarkCardsFromCollections :
      bookmarkCardsFromCollections;
    const isCollaborateCollection = _.get(collection, 'is_collaborate_collection', false);
    const isRestricted = isRestrictedCollection(collection);

    if (!_.isEmpty(dateFilters)) {
      dispatchCollectionDateFilters(dateFilters)
    }
    if (isRestricted || isMyViews(collection)) {
      // Closing metric library for Restricted Collection
      this.props.dispatchSetIsManageMetricLibrary(false);
    }

    if (!_.isEqual(currentCollection.id, collection.id)) {
      dispatchSetIsManageCollection(isCollaborateCollection);
      dispatchSetCurrentCollectionId(collectionId);
      dispatchSetBellerophonCollectionTagId(collectionTagId);
      updateCurrentCollectionId(collectionId, currentCollection.id);
      const isBookmarkFetching = (isMyViews(collection) || isCollaborateCollection);
      this.setState({
        currentCollection: collection,
        cardEntries: [],
        isCollectionCardLoading: isBookmarkFetching,
        newLabelCards: updatedNewLabelCards,
        bookmarkCardsFromCollections: newBookmarkCards
      }, () => {
        if (isBookmarkFetching) {
          // Fetching bookmark cards and collection cards
          this.fetchBookMarks();
        } else {
          // Fetching collection cards
          this.fetchCollectionCards();
        }
      });
    } else {
      this.setState({ currentCollection: collection, newLabelCards: updatedNewLabelCards });
    }
  };

  handleCollectionFiltersChange = (collectionFilters) => {
    this.setState({ collectionFilters });
    this.props.dispatchCollectionFiltersChange(collectionFilters);
  }

  onReloadCollection = (isReload) => {
    this.setState({ reloadCollection: isReload });
  }

  handleCardPositionChange = (options) => {
    cardEntries
    const { type, cardEntries, orderedUserCards } = options;
    const { currentCollection } = this.state;
    const isRestricted = isRestrictedCollection(currentCollection);
    if (isRestricted) return null;

    if (type === 'bookmark') {
      this.setState({
        bookmarks: cardEntries,
        cardEntries,
      });
    } else {
      this.setState({
        cardEntries: cardEntries,
        userCards: orderedUserCards
      });
    }
  };

  removeCardFromEntries = (deletedCard) => {
    const { userCards, cardEntries } = this.state;
    const isBookmarkCard = _.get(deletedCard, 'userCardEntry.isBookmarkCard', false);
    const deletedCardId = isBookmarkCard ?
      _.get(deletedCard, 'userCardEntry.cardEntry.id') :
      _.get(deletedCard, 'userCardEntry.id');
    const newCardEntries = _.filter(cardEntries, (card) => {
      const isBookmarkTypeCard = _.get(card, 'isBookmarkCard', false);
      const cardId = isBookmarkTypeCard ? _.get(card, 'cardEntry.id') :
        _.get(card, 'userCardEntry.id');

      return !_.isEqual(deletedCardId, cardId);
    });
    const newUserCards = _.filter(userCards, (card) => {
      return !_.isEqual(deletedCardId, _.get(card, 'id'));
    });
    this.setState({ cardEntries: newCardEntries, userCards: newUserCards });
  };

  handleDropIntoCollection = (card, dropCollection) => {
    const isSharedAccepted = _.get(dropCollection, 'is_shared_accepted', false);
    const isMetricLibrary = _.get(card, 'isMetricLibrary', false);

    if (!isSharedAccepted || isCollectionViewAccess(dropCollection) || !isMetricLibrary) {
      return;
    }

    if (_.get(card, 'viewEntry.isRadar', false) || _.get(card, 'userCardEntry.isExplorationCard', false)) {
      return;
    }
    this.handleCopyAllMetricCard(card, dropCollection);
  }

  handleCopyAllMetricCard = (card, collection) => {
    const { templateEntry, viewEntry } = card;
    const { dispatchCopyCardInCollection } = this.props;
    let isNewCard = false;
    const newLabelCards = []; //this.getNewLabelCards(collectionWithNewLabelCards, collection);

    const matchedCard = _.find(newLabelCards, { template_card_id: cardId });

    isNewCard = isNewMetricLabelCard(viewEntry) && !_.isEmpty(matchedCard)
    const isManageView = isCollaborateCollection(collection);
    const userCardEntryId = _.get(card, 'userCardEntry.id');
    const isBookmarkCard = _.get(card, 'userCardEntry.isBookmarkCard', false);
    const cardId = isBookmarkCard ? userCardEntryId : getCardId(templateEntry, viewEntry);
    const bookmarkId = isBookmarkCard ? userCardEntryId : null;
    const collectionId = _.get(collection, 'id');

    this.abortCopyCardsApi.abort();
    this.abortCopyCardsApi = new AbortController();
    this.setState({ isLoading: true });
    dispatchCopyCardInCollection(collectionId, cardId, templateEntry, viewEntry);

    createCard(
      collectionId,
      {
        card_id: cardId, bookmark_id: bookmarkId, isNewCard, isManageView,
        exploration_id: _.get(card, 'userCardEntry.exploration_id'), is_exploration_card: false
      },
      { signal: this.abortCopyCardsApi.signal }
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({ isLoading: false }, () => {
          const { userCards, cardEntries } = this.state;
          var clonedUserCards = _.cloneDeep(userCards);
          var clonedCardEntries = _.cloneDeep(cardEntries);
          var isNewCardWithoutId = _.some(clonedUserCards, (cardDatum) => {
            if (_.get(cardDatum, 'id', 0) == 0) {
              return cardDatum;
            }
          });

          if (isNewCardWithoutId) {
            const newCardIndex = _.findIndex(clonedUserCards, (userCard) => {
              return userCard.card_id == response.card_id;
            });

            clonedUserCards[newCardIndex] = _.merge(response, clonedUserCards[newCardIndex]);
            clonedCardEntries[newCardIndex]['userCardEntry'] = response;

            updateCardsOrder(collectionId, clonedUserCards);
            this.setState({
              userCards: clonedUserCards,
              cardEntries: clonedCardEntries
            });
          } else {
            this.fetchBookMarks();
          }
        });
        if (response.error) {
          let toastMessage = metricCopiedErrorMessage;
          toast.error(toastMessage);
        } else {
          let toastMessage = metricCopiedMessage;
          toast.success(toastMessage);
        }
      })
      .catch((error) => {
        const toastMessage = metricCopiedErrorMessage;
        toast.error(toastMessage);
        console.log('Error on Metric copy', error);
        this.setState({ isLoading: false });
      });
  };

  renderCardList() {
    const { commonFilters, currentUser, userCollectionsWithCards, currentCollectionId,
      currentCollectionTagId } = this.props;

    const {
      allBookmarks,
      componentUpdateCounter,
      isLoading,
      currentCollection,
      isCardsLoading,
      isCollectionCardLoading,
      collectionFilters,
      userCards,
      newLabelCards,
      cardEntries
    } = this.state;
    let cardType = this.getCurrentCardType();
    return (
      <DropTargetCard
        key={currentCollection.id}
        collection={currentCollection}
        isDroppable={true}
        onDrop={(item) => this.handleDropIntoCollection(item, currentCollection)} >
        <div>
          <CardList
            onReloadCollection={this.onReloadCollection}
            newLabelCards={newLabelCards}
            bookmarks={allBookmarks}
            globalFilters={collectionFilters}
            onCardDelete={this.removeCardFromEntries}
            isLoading={isLoading || isCardsLoading}
            isCollectionCardLoading={isCollectionCardLoading}
            currentUser={currentUser}
            cardType={cardType}
            userCards={userCards}
            collectionEntry={currentCollection}
            reloadBookmarks={this.fetchBookMarks}
            commonFilters={commonFilters}
            cardEntries={cardEntries}
            onCardMove={this.handleCardPositionChange}
            componentUpdateCounter={componentUpdateCounter}
            userCollectionsWithCards={userCollectionsWithCards}
            currentCollectionId={currentCollectionId}
            currentCollectionTagId={currentCollectionTagId}
          />
        </div>
      </DropTargetCard>
    );
  }

  // We show the dummy card because we load the actual metrics card based on screen size instead of
  // setting the default card count. So we need at least one card to calculate the width of a card.
  renderDummyCards = () => {
    const { isLoading, isCardsLoading } = this.state;
    return (
      <div className="template-cards">
        <DummyCard isLoading={isLoading || isCardsLoading} />
      </div>
    );
  }

  handleClickMetricLibrary = (isOpenMeticLibrary) => {
    this.props.dispatchSetIsManageMetricLibrary(isOpenMeticLibrary);
  }

  renderMetricLibraryDrawer = () => {
    const {
      currentUser,
      currentCollectionId,
      currentCollectionTagId,
      commonFilters,
      isManageMetricLibrary,
      templateEntries
    } = this.props;

    const { currentCollection, bookmarks } = this.state;

    if (!isManageMetricLibrary) {
      return null;
    }
    const bookmarkEntries = this.getViewBookmarks(bookmarks, true);

    const metricLibraryOption = {
      currentUser,
      currentCollectionId,
      currentCollectionTagId,
      commonFilters,
      isManageMetricLibrary,
      onHandleMetricLibrary: this.handleClickMetricLibrary,
      cardEntries: getAllMetricEntries(templateEntries),
      templateEntries: templateEntries,
      userCards: this.state.userCards,
      bookmarkEntries: bookmarkEntries,
      placeCardIntoCollection: (card) =>
        this.handleDropIntoCollection(card, currentCollection),
      removeCardFromCollection: this.removeCardFromEntries,
    }

    return(
      <MetricDrawer metricLibraryOption={metricLibraryOption}/>
    )
  }

  render() {
    const {
      isLoading,
      allBookmarks,
      isCardsLoading,
      currentCollection,
      collectionFilters,
      cardEntries,
      newLabelCards,
      reloadCollection
    } = this.state;
    const {
      currentUser,
      currentCollectionId,
      currentCollectionTagId,
      allMetricFilters,
      dispatchAllMetricFiltersChange,
      commonFilters,
      dispatchUpdateBookmarkId,
      dispatchSetCurrentCollectionId,
      collectionDateFilters,
      globalFilters,
      isOverwriteCollectionFilter,
      dispatchSetOverwriteCollectionFilter,
      isManageMetricLibrary,
      showOnboardWizard
    } = this.props;
    const cardType = this.getCurrentCardType();
    let availableTemplateIds = _.flatMap(cardEntries, (cardEntry) => {
      const newCardType = _.get(cardEntry, 'isBookmarkCard') ? CARD_TYPES.BOOKMARK : cardType;
      if (_.get(cardEntry, 'is_radar') && newCardType == CARD_TYPES.BOOKMARK) {
        const radarMetricEntries = _.get(cardEntry, 'radarOptions.selectedMetricEntries', [])
        const radarTemplateIds = _.map(radarMetricEntries, 'template_id');
        return radarTemplateIds;
      } else if (_.get(cardEntry, 'isExplorationCard', false)) {
        return null;
      } else if (newCardType == CARD_TYPES.BOOKMARK) {
        const { currentDrilldownTemplateId } = _.get(cardEntry, 'drilldown', {});
        return _.toString(currentDrilldownTemplateId);
      } else if (!_.get(cardEntry, 'isRadarCard', false)) {
        const { templateEntry } = cardEntry;
        return `${templateEntry['template_id']}`;
      }
    });
    availableTemplateIds = _.compact(availableTemplateIds);

    return (
      <div className="dashboard pb-5" id='dashboard-container'>
        <DragAndDrop>
          <Collections
            newLabelCards={newLabelCards}
            isCardsLoading={isCardsLoading}
            allMetricFilters={allMetricFilters}
            commonFilters={commonFilters}
            collectionFilters={collectionFilters}
            currentCollectionId={currentCollectionId}
            currentCollectionTagId={currentCollectionTagId}
            onCardDelete={this.removeCardFromEntries}
            currentCollection={currentCollection}
            bookmarks={allBookmarks}
            onCollectionChange={this.handleCollectionChange}
            onUpdateCollection={this.onUpdateCollection}
            currentUser={currentUser}
            cardEntries={cardEntries}
            onAllMetricFilterChanges={dispatchAllMetricFiltersChange}
            onCollectionFiltersChange={this.handleCollectionFiltersChange}
            onManageClick={dispatchUpdateBookmarkId}
            availableTemplateIds={availableTemplateIds}
            onAcceptAllBookmarkClick={this.handleAcceptAllBookmark}
            collectionDateFilters={collectionDateFilters}
            globalFilters={globalFilters}
            isOverwriteCollectionFilter={isOverwriteCollectionFilter}
            updateOverwriteCollectionFilter={dispatchSetOverwriteCollectionFilter}
            isManageMetricLibrary={isManageMetricLibrary}
            onHandleMetricLibrary={this.handleClickMetricLibrary}
            reloadCollection={reloadCollection}
            dispatchSetCurrentCollectionId={dispatchSetCurrentCollectionId}
            onReloadCollection={this.onReloadCollection} />
          {hasNoAccessToCollection({id: currentCollectionId}) ? <AccessDenied /> :
            <div className="dashboard-cards container-fluid" role="list">
              <LoadingSpinner isLoading={isLoading || isCardsLoading} />
              {this.renderCardList()}
              {this.renderDummyCards()}
              <CollaborateCollectionCreatedAt isDashboard={true}
                collection={currentCollection} className="create-time-stamp" />
            </div>
          }
          {this.renderMetricLibraryDrawer()}
        </DragAndDrop>
        {showOnboardWizard && !canUserRestrictCollections()
          && <OnboardWizard onReloadCollection={this.onReloadCollection} />
        }
      </div>

    );
  }
}

Dashboard.propTypes = {
  commonFilters: commonFiltersPropTypes,
  currentCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentCollectionTagId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentUser: userPropTypes,
  userCollectionsWithCards: PropTypes.array,
  templateEntries: templateEntriesPropTypes,
  collectionDateFilters: PropTypes.object,
  globalFilters: PropTypes.array,
  isOverwriteCollectionFilter: PropTypes.bool,
  isManageMetricLibrary: PropTypes.bool,
  dispatchSetCurrentCollectionId: PropTypes.func,
  dispatchRemovePolygonFilter: PropTypes.func,
  dispatchCollectionFiltersChange: PropTypes.func,
  dispatchAllMetricFiltersChange: PropTypes.func,
  dispatchUpdateBookmarkId: PropTypes.func,
  allMetricFilters: PropTypes.array,
  dispatchRemoveInsightsId: PropTypes.func,
  dispatchDefaultTemplateEntry: PropTypes.func,
  dispatchSetBellerophonCollectionTagId: PropTypes.func,
  dispatchSetOverwriteCollectionFilter: PropTypes.func,
  dispatchCollectionDateFilters: PropTypes.func,
  dispatchSetIsManageCollection: PropTypes.func,
  dispatchSetIsManageMetricLibrary: PropTypes.func,
  dispatchCopyCardInCollection: PropTypes.func,
  showOnboardWizard: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    userCollectionsWithCards: _.get(state, 'userCollections.userCollectionsWithCards', []),
    currentCollectionId: _.get(state, 'dashboard.currentCollectionId'),
    currentCollectionTagId: _.get(state, 'dashboard.currentCollectionTagId'),
    isOverwriteCollectionFilter: _.get(state, 'dashboard.isOverwriteCollectionFilter'),
    currentUser: _.get(state.currentUser, 'user', {}),
    templateEntries: _.get(state, 'configurations.template_entries', []),
    commonFilters: _.get(state, 'commonFilters', {}),
    allMetricFilters: _.get(state, 'commonFilters.allMetricFilters', []),
    collectionDateFilters: _.get(state, 'commonFilters.collectionDateFilters', {}),
    globalFilters: _.get(state, 'commonFilters.globalFilters', []),
    isManageMetricLibrary: _.get(state, 'dashboard.isManageMetricLibrary', false),
    showOnboardWizard: _.get(state, 'userLoggedInDetails.show_onboard_wizard', true)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetCurrentCollectionId: (metricTypeName) => {
      dispatch(setCurrentCollectionId(metricTypeName));
    },
    dispatchSetIsManageCollection: (isManageCollection = false) => {
      dispatch(setIsManageCollection(isManageCollection));
    },
    dispatchSetBellerophonCollectionTagId: (collectionTagId) => {
      dispatch(setBellerophonCollectionTagId(collectionTagId));
    },
    dispatchDefaultTemplateEntry: (templateEntry) => {
      dispatch(setDefaultTemplateEntry(templateEntry));
    },
    dispatchRemovePolygonFilter: () => {
      dispatch(removePolygonFilter());
    },
    dispatchUpdateBookmarkId: () => {
      dispatch(updateCurrentBookmark());
    },
    dispatchCollectionFiltersChange: (collectionFilters) => {
      dispatch(updateGlobalFilter(collectionFilters));
    },
    dispatchAllMetricFiltersChange: (filters) => {
      dispatch(updateAllMetricsFilter(filters));
    },
    dispatchRemoveInsightsId: () => {
      dispatch(updateInsightsId(''));
    },
    dispatchCollectionDateFilters: (dateFilters) => {
      dispatch(updateCollectionDateFilters(dateFilters));
    },
    dispatchSetOverwriteCollectionFilter: (isOverwriteFilter) => {
      dispatch(setOverwriteCollectionFilter(isOverwriteFilter));
    },
    dispatchSetIsManageMetricLibrary: (isManageMetricLibrary) => {
      dispatch(setIsManageMetricLibrary(isManageMetricLibrary));
    },
    dispatchCopyCardInCollection: copyCardInCollection
  };
}

// Dashboard.defaultProps = defaultProp;

export default connect(mapStateToProps, mapDispatchToProps)(PageScrollHandlerWrapper(Dashboard));
