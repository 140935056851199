import _ from 'lodash';
import moment from 'moment/moment';
import {
  CURRENCY_TYPE,
  DEFAULT_ARROW_COLOR,
  DEFAULT_MAP_VIEW,
  DEFAULT_UP_OR_DOWN_ARROW_COLOR,
  OVERTIME_VISUALIZATION_TYPES,
  SNAPSHOT_VISUALIZATION_TYPES,
  STRING_TYPES_FIELD,
  VISUALIZATION_TYPES,
  TARGET_ENTRY_TYPES,
  COMPARE_VIEW_DRILLDOWN_OPTIONS,
  LAST_PERIOD_COMPARISON_TYPE,
  COMPARE_YEAR_TYPES,
  BENCHMARK_LINE_TYPE,
  DISCRETE_AGGREGATE_TYPES,
  CUMULATIVE_AGGREGATE_TYPES,
  GEO_LOCATION_COLUMN_TYPES,
  URL_COLUMN_TYPES,
  SORT_BY_OPTIONS,
  COMPARISON_MODE_OPTIONS,
  NONE_DIMENSION_FIELD
} from 'appConstants';

import {
  getDefaultDateMode
} from 'helpers/dateHelper';
import { getCurrentDrilldownDimensionColumn } from 'helpers/chartDataHelper';
import { isLocationsConfigured } from 'modules/Map/helpers/MapOptionsHelper';
import {
  getPerviousComparisonYearDateRangeForRange,
  getCompareSamePeriodLastYearOptions,
  getComparisonYearDateRangeForPeriod
} from 'helpers/fiscalPeriodUtils';
import { getBarDimensionConfigs } from 'modules/visualization/SnapshotVisualization/tickLabelToolTipHelper';
import {
  getPrimaryMetricName,
  getSecondaryMetricNameForSnapshot,
  getDimensionName,
  getGroupName
} from 'helpers/displayNameHelper';
import { isDateColumnConfigured, getComparisonPeriodDateRanges } from 'helpers/dateHelper';
import { disableTableVisualization } from 'modules/DetailsTable/TableHelper';
import { getDistributionBenchmarkEntries } from 'helpers/distributionHelper';
import {
  getTargetBenchMarkEntry,
  getTargetMetricEntry,
  isDateRangeFirstOrLastPeriodCategory
} from 'common/config/viewConfiguration';
import {
  shouldShowRangeChart,
  shouldShowPieChart,
  shouldShowAreaChart,
  shouldShowBurnUpChart,
  getDistributionBucketEntry,
  getDefaultCompareToYear,
  getCustomCompareToYears,
  isComboChartEnable,
} from 'common/config/visualizationConfiguration';
import { hideTableVisualization } from 'common/config/templateConfiguration';
import { isBienniumFiscalYear } from 'modules/visualization/LineChart/Helpers/bienniumFiscalYearHelper';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import { isStackTypeChart } from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';
import {
  getCurrentTemplateEntry,
  getDimensionEntries,
  getGroupByEntries
} from 'common/config/templateConfiguration';
import { allowLeafPageOnTableClick } from 'common/config/customerConfiguration';

export const getSortDropdownOptions = (
  currentDrilldownDimensionField, currentDrilldownViewEntry, templateId ) => {
  const viewField = _.get(currentDrilldownViewEntry, 'field', []);
  const templateEntry = getCurrentTemplateEntry(templateId);
  const dimensionEntries = getDimensionEntries(templateId);
  const dimensionEntry =  _.find(dimensionEntries, (entry) =>{
    return (entry['field'] === currentDrilldownDimensionField)
  });
  let newCustomSortOptions = [];
  if(templateEntry['enable_custom_sort_order'] == 'true'){
    newCustomSortOptions = getMatchedCustomSortOptions(templateEntry, dimensionEntry)
  }
  const sortByField = currentDrilldownDimensionField || viewField;
  const sortOptions =  sortDropdownOptions(sortByField, viewField);
  return _.concat(sortOptions, newCustomSortOptions);
}

export const getGroupBySortDropdownOptions = (
  currentDrilldownGroupByEntry, currentDrilldownViewEntry, templateId) => {
  const viewField = _.get(currentDrilldownViewEntry, 'field', '');
  const groupField = _.get(currentDrilldownGroupByEntry, 'field', '');
  const templateEntry = getCurrentTemplateEntry(templateId);
  const groupByEntries = getGroupByEntries(templateId, true);
  const groupByEntry =  _.find(groupByEntries, (entry) =>{
    return (entry['field'] === groupField)
  });
  let newCustomSortOptions = [];
  if(
      templateEntry['enable_custom_sort_order'] == 'true' &&
      templateEntry['enable_custom_sort_order_for_group_by'] == 'true' &&
      !_.isEmpty(groupByEntry)
    ){
    newCustomSortOptions = getMatchedCustomSortOptions(templateEntry, groupByEntry);
  }
  const sortOptions =  sortDropdownOptions(groupField, viewField);
  return _.concat(sortOptions, newCustomSortOptions);
}

const sortDropdownOptions = (groupOrDimensionField, viewField) => {
  return ([
    {
      name: "A - Z",
      sortBy: groupOrDimensionField,
      sortOrder: 'asc',
      type: _.get(SORT_BY_OPTIONS, [0, 'type'])
    },
    {
      name: "Z - A",
      sortBy: groupOrDimensionField,
      sortOrder: 'desc',
      type: _.get(SORT_BY_OPTIONS, [1, 'type'])
    },
    {
      name: "Low to High",
      sortBy: viewField,
      sortOrder: 'asc',
      type: _.get(SORT_BY_OPTIONS, [2, 'type'])
    },
    {
      name: "High to Low",
      sortBy: viewField,
      sortOrder: 'desc',
      type: _.get(SORT_BY_OPTIONS, [3, 'type'])
    }
  ]);
}

export const getSortByOption = (currentDrilldownEntry, sortType, dropdownType) => {
  const {
    currentDrilldownGroupByEntry,
    currentDrilldownViewEntry,
    currentDrilldownDimensionField,
    currentDrilldownTemplateId
  } = currentDrilldownEntry;


  let sortOptions = [];
  if (_.isEqual(dropdownType, 'groupBySort')) {
    sortOptions = getGroupBySortDropdownOptions(
      currentDrilldownGroupByEntry, currentDrilldownViewEntry, currentDrilldownTemplateId);
  } else {
    sortOptions = getSortDropdownOptions(
      currentDrilldownDimensionField, currentDrilldownViewEntry, currentDrilldownTemplateId);
  }
  let currentOption = _.find(sortOptions, (option) => {
    return (_.isEqual(option['type'], sortType))
  });
  return _.isEmpty(currentOption) ? sortOptions[0] : currentOption;
}


export const getSecondaryMetricEntries = (currentViewEntry, visualizationType, chartType) => {
  let secondaryMetricEntries = _.get(currentViewEntry,
    `visualization.${visualizationType}.${chartType}.secondary_metric_entries`, []);
  return secondaryMetricEntries;
}

export const  getBenchMarkEntries = (currentViewEntry, visualizationType, chartType, options = {}) => {
  let benchmarkEntries = _.get(currentViewEntry,
      `visualization.${visualizationType}.${chartType}.bench_mark_entries`, []);

  if (visualizationType === 'distribution') {
    const { isCumulative, isDiscrete, quickFilters } = options;
    const distributionBenchmarkEntries = getDistributionBenchmarkEntries(currentViewEntry);
    benchmarkEntries = _.filter(distributionBenchmarkEntries, (benchmarkEntry) => {
      const {
        bucket_aggregate_type, only_for_metric_defaults, filter_column, filter_value
      } = benchmarkEntry;
      const currentFilter = getBenchmarkFilter(benchmarkEntry, quickFilters);
      const benchmarkHideCondition = filter_column && filter_value ?
        _.isEmpty(currentFilter) :
        (only_for_metric_defaults === 'true') && !_.isEmpty(quickFilters);

      if (benchmarkHideCondition) { return false; }

      return (
        isDiscrete && _.includes(DISCRETE_AGGREGATE_TYPES, bucket_aggregate_type) ||
        isCumulative && _.includes(CUMULATIVE_AGGREGATE_TYPES, bucket_aggregate_type)
      );
    });
  }

  _.forEach(benchmarkEntries, (benchmark, index) => {
      benchmark['lineType'] = BENCHMARK_LINE_TYPE[index];
  });
  return benchmarkEntries;
}

export const getDefaultSecondaryMetricEntry = (currentViewEntry, visualizationType, chartType) => {
  const secondaryMetricEntry = getSecondaryMetricEntries(currentViewEntry, visualizationType, chartType);
  const defaultMetricName = _.get(currentViewEntry,
    `visualization.${visualizationType}.${chartType}.default_secondary_metric`, '');
  const defaultMetricEntry = _.find(secondaryMetricEntry, (entry) => {
    return entry.name === defaultMetricName;
  });

  return defaultMetricEntry || {};
}

export const getSecondaryMetricEntry = (
  currentViewEntry, visualizationType, chartType, secondaryMetricField
) => {
  const secondaryMetricEntry = getSecondaryMetricEntries(currentViewEntry, visualizationType, chartType);
  const currentMetricEntry = _.find(secondaryMetricEntry, (entry) => {
    return entry.field === secondaryMetricField;
  });

  return currentMetricEntry || {};
}

export const getBenchMarkMetricEntry = (
  currentViewEntry, visualizationType, chartType, benchMarkMetricName
) => {
  const benchMarkMetricEntry = getBenchMarkEntries(currentViewEntry, visualizationType, chartType);
  const currentMetricEntry = _.find(benchMarkMetricEntry, (entry) => {
    return entry.name === benchMarkMetricName;
  });

  return currentMetricEntry || {};
}
export const getBenchMarkMetricEntries = (
  currentViewEntry,
  visualizationType,
  chartType,
  benchMarkMetricNames,
  options = {}
) => {
  const benchMarkMetricEntries = getBenchMarkEntries(
    currentViewEntry, visualizationType, chartType, options);
  const currentMetricEntries = _.filter(benchMarkMetricEntries, (entry) => {
    return _.includes(benchMarkMetricNames, entry.name);
  });

  return _.compact(currentMetricEntries) || [];
}

export const getDefaultBenchMarkEntries = (
  currentViewEntry, visualizationType, chartType, templateId, dimensionField
) => {
  const dimensionColumn = getCurrentDrilldownDimensionColumn(templateId, dimensionField);
  const benchMarkEntries = getBenchMarkEntries(currentViewEntry, visualizationType, chartType);
  const defaultBenchMarkNames = _.get(currentViewEntry,
    `visualization.${visualizationType}.${chartType}.default_bench_marks`, []);

  if(_.isEmpty(defaultBenchMarkNames)){
    return [];
  }

  const dimensionBasedBenchmarks = _.compact(_.map(benchMarkEntries, (benchmark) => {
    if(_.isEmpty(benchmark.dimension_column) || benchmark.dimension_column === dimensionColumn ){
      return benchmark;
    }
  }));

  const  defaultBenchMarkEntries = _.filter(dimensionBasedBenchmarks, (benchmark) => {
    return _.includes(defaultBenchMarkNames, benchmark.name);
   });

  if(!_.isEmpty(defaultBenchMarkEntries)){
    return defaultBenchMarkEntries;
  } else {
    return  !_.isEmpty(dimensionBasedBenchmarks) ? [dimensionBasedBenchmarks[0]] : [];
  }
}

export const getDefaultSortOption = (
  currentDrilldownDimensionField, currentDrilldownViewEntry, sortType, currentDrilldownTemplateId
) => {
  let sortOptions = getSortDropdownOptions(
    currentDrilldownDimensionField, currentDrilldownViewEntry, currentDrilldownTemplateId);
  const currentSortType = (sortType || 'desc');
  const currentSortOption = _.find(sortOptions, (option) => {
    return option.type == currentSortType
  });
  return currentSortOption || sortOptions[0]
}

export const getDefaultGroupBySortOption = (
  currentDrilldownGroupByEntry, currentDrilldownViewEntry, sortType, templateId
) => {
  let sortOptions = getGroupBySortDropdownOptions(
    currentDrilldownGroupByEntry, currentDrilldownViewEntry, templateId);
  const currentSortType = (sortType || 'a-z');
  const currentSortOption = _.find(sortOptions, (option) => {
    return option.type == currentSortType
  });
  return currentSortOption || sortOptions[0]
}

export const getVisualizationTypes = () => {
  return [
    VISUALIZATION_TYPES.SNAPSHOT,
    VISUALIZATION_TYPES.OVERTIME,
    VISUALIZATION_TYPES.DISTRIBUTION,
    VISUALIZATION_TYPES.MAP,
    VISUALIZATION_TYPES.TIME_OF_DAY,
    VISUALIZATION_TYPES.TABLE
  ];
}

export const shouldHideVisualizationButton = (templateId, currentDrilldownViewEntry, visualizationType) => {
  if (visualizationType === 'map') {
    const mapTypes = _.get(currentDrilldownViewEntry, 'visualization.map.map_types');
    if (_.isEmpty(mapTypes)) {
      return true;
    }
  }

  if(visualizationType === VISUALIZATION_TYPES.TABLE.type && hideTableVisualization(templateId)){
    return true;
  }

  return _.get(
    currentDrilldownViewEntry, `visualization.${visualizationType}.hide_this_visualization`, 'false'
  ) == 'true';
}

export const getSnapshotVisualizationTypes = (viewEntry) => {
  const chartTypes = [
    SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART,
  ];

  if(shouldShowRangeChart(viewEntry)){
    chartTypes.push(SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT)
  }

  if(shouldShowPieChart(viewEntry)) {
    chartTypes.push(SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART)
  }
  return chartTypes;
}

export const getDefaultVisualizationChartView = (viewEntry, visualizationView) => {
  const defaultViews = {
    'snapshot': SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type,
    'overtime': OVERTIME_VISUALIZATION_TYPES.TIMELINE.type,
    'map': DEFAULT_MAP_VIEW
  };
  return _.get(viewEntry,
    `visualization.${visualizationView}.default_view`, defaultViews[visualizationView]);
}

export const getSelectedLineChartViewEntry = (chartType) => {
  let chartTypes = {};
  chartTypes['timeline'] = OVERTIME_VISUALIZATION_TYPES.TIMELINE;
  chartTypes['area'] = OVERTIME_VISUALIZATION_TYPES.AREA;
  chartTypes['burn_up'] = OVERTIME_VISUALIZATION_TYPES.BURN_UP;
  return chartTypes[chartType] || OVERTIME_VISUALIZATION_TYPES.TIMELINE;
}

export const getDefaultShowByValue = (viewEntry) => {
  const defaultShowByType = _.last(COMPARE_VIEW_DRILLDOWN_OPTIONS).type
  const configuredShowByType = _.get(viewEntry,
    'visualization.snapshot.show_by_default', defaultShowByType);
  return _.get(_.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { type: configuredShowByType }), 'name');
}

export const getDefaultDimensionColumn = (viewEntry) => {
  return _.get(viewEntry, 'visualization.snapshot.default_dimension_by_entry_column', '');
}

export const getOvertimeChartOptions = (viewEntry, hideForForecastingView = false) => {
  const chartOptions = [OVERTIME_VISUALIZATION_TYPES.TIMELINE];

  if(shouldShowAreaChart(viewEntry) && !hideForForecastingView) {
    chartOptions.push(OVERTIME_VISUALIZATION_TYPES.AREA);
  }

  if(shouldShowBurnUpChart(viewEntry) && !isDateRangeFirstOrLastPeriodCategory(viewEntry)) {
    chartOptions.push(OVERTIME_VISUALIZATION_TYPES.BURN_UP);
  }
  return chartOptions;
}

export const getDefaultCompareYearRange = (viewEntry, commonFilters, templateId) => {
  let defaultYear = getDefaultCompareToYear(viewEntry);
  let dateRangeMode = _.get(commonFilters, 'dateType', getDefaultDateMode());
  const dateRange = _.get(commonFilters, 'dateRange', {});
  if(_.isEmpty(dateRangeMode)) {
    dateRangeMode = getDefaultDateMode();
  }

  if(_.isEmpty(defaultYear)) {
    return [];
  }

  if (isBienniumFiscalYear({ dateRangeMode })) {
    if(defaultYear !== COMPARE_YEAR_TYPES.SAME_LAST_PERIOD && defaultYear) {
      const parsedDefaultYearRange = JSON.parse(defaultYear);
      const  { startDate, endDate } = parsedDefaultYearRange;
      const endYear = Number(moment(endDate).format('YYYY'));
      const startYear = Number(moment(startDate).format('YYYY'));

      return [getComparisonYearDateRangeForPeriod(dateRange, startYear, endYear)];
    } else {
      return [];
    }
  }

  const yearOptions = getCompareSamePeriodLastYearOptions({
    primaryDateRange: dateRange, primaryDateType: dateRangeMode, templateId}
  );
  if (_.includes(
    [
      LAST_PERIOD_COMPARISON_TYPE.name,
      COMPARE_YEAR_TYPES.SAME_LAST_PERIOD,
      COMPARE_YEAR_TYPES.PRIOR_PERIOD], defaultYear)
  ) {
      return [getPerviousComparisonYearDateRangeForRange(dateRange)];
  } else if (_.includes([COMPARE_YEAR_TYPES.SAME_LAST_TWO_PERIODS], defaultYear)) {
    const lastYearDateRange = getPerviousComparisonYearDateRangeForRange(dateRange);
    const yearBeforeLastDataRange = getPerviousComparisonYearDateRangeForRange(lastYearDateRange);
    return [
      lastYearDateRange,
      yearBeforeLastDataRange
    ];
  } else if(defaultYear === COMPARE_YEAR_TYPES.CUSTOM){
      let customYears = getCustomCompareToYears(viewEntry);
      const selectYearEntries = _.filter(yearOptions, (yearEntry) => {
        return _.includes(customYears, yearEntry['name']);
      });
      return _.map(selectYearEntries, (entry) => {
        return entry['range'];
      });
  } else {
    const selectedYearDateRange = _.find(yearOptions, (yearEntry) => {
      return yearEntry['name'] == defaultYear;
    });

    return _.isEmpty(selectedYearDateRange) ?
      [getPerviousComparisonYearDateRangeForRange(dateRange)] :
      [selectedYearDateRange['range']];
  }
}

export const getFilterObject = (
  isNumber, column, field, selectedValue, dimensionIndex, isRange
) => {
  let fromValue = isNumber ? selectedValue : [selectedValue];
  let toValue = null;

  const numericalValues = _.split(selectedValue,'-');

  if(_.size(numericalValues) === 3 && isNumber) {
    fromValue = _.isEmpty(numericalValues[0]) ? - numericalValues[1] : numericalValues[0];
    if (_.isEmpty(numericalValues[0]) && !_.isEmpty(numericalValues[2])) {
      toValue = numericalValues[2];
    } else if (_.isEmpty(numericalValues[1])) {
      toValue = - numericalValues[2];
    } else {
      toValue = numericalValues[1];
    }
  } else if(_.size(numericalValues) > 1 && isNumber) {
    fromValue = numericalValues[0];
    toValue = numericalValues[1];
  }

  const type = isNumber ? 'number' : 'text';
  const operator = (isNumber && isRange) ? 'between' : '=';

  return {
    type, column, field, operator,
    index: dimensionIndex,
    values: isNumber ? null : [selectedValue],
    value: isNumber ? Number(fromValue) : null,
    to: toValue
  }
}

export const getFilterCommonObject = (
  type, operator, currentDrilldown, fieldName, fromValue, toValue, dimensionIndex
) => {
  let filterObject = {
    'type': type,
    'column':_.get(currentDrilldown, 'column'),
    'operator': operator,
    'field': fieldName
  };

  if (type === 'text') {
    filterObject = _.merge({}, filterObject, { values: fromValue, index: dimensionIndex });
  } else {
    const overrideFilterObject = { value: Number(fromValue), to: Number(toValue), index: dimensionIndex };
    filterObject = _.merge({}, filterObject, overrideFilterObject);
  }
  return filterObject;
}

export const shouldDisableDistributionTab = (currentDrilldownViewEntry) => {
  const bucketEntry = getDistributionBucketEntry(currentDrilldownViewEntry);
  const showDistributionTab = _.get(currentDrilldownViewEntry, 'distribute_column', 'true');
  return _.isEmpty(bucketEntry) || showDistributionTab == 'false';
}

export const getAvailableVisualizationType = (
  templateId, vizType, currentDrilldownViewEntry
) => {
  const currentVisualizationType = vizType == 'none' ?  'snapshot' : vizType;
  if(!shouldHideVisualizationButton(templateId, currentDrilldownViewEntry, currentVisualizationType)) {
    return currentVisualizationType;
  }

  const availableVizTypes = _.chain(_.get(currentDrilldownViewEntry, 'visualization')).
    omit('default_view').
    keys().
    filter((visualizationType) => {
      if (!shouldHideVisualizationButton(templateId, currentDrilldownViewEntry, visualizationType)) {
        return visualizationType;
      }
    }).
    value()
  return _.first(availableVizTypes);
}

export const getNewFilters = (filters, filterObject) => {
  const { type, column, operator, values, field } = filterObject;
  const selectedFilter = _.find(filters, { 'field': field });

  if (!_.isEmpty(selectedFilter)) {
    return _.map(filters, (filter) => {
      return (filter.field === field) ? getUpdateFilterValue(filter, filterObject) : filter;
    });
  }

  if (filterObject.type === STRING_TYPES_FIELD) {
    filters.push({ type, column, field, conditions: [{ operator, value: values }]});
  } else {
    filters.push(filterObject);
  }

  return filters;
}

function getUpdateFilterValue(filter, filterObject){
  const { operator, values } = filterObject;

  if (filter.type === STRING_TYPES_FIELD) {
    const selectedFilterCondition = _.find(filter.conditions, { operator });
    if (selectedFilterCondition) {
      const newFilterConditions = _.map(filter.conditions, (condition) => {
        if (condition.operator === operator) {
          condition['value'] = values;
        }
        return condition;
      });
      filter['conditions'] = newFilterConditions;
    } else {
      filter.conditions.push({ operator, value: values });
    }
    return filter;
  } else {
    return filterObject;
  }
}

export const isGeoLocationOrUrlColumn = (column) => {
  return _.includes([...GEO_LOCATION_COLUMN_TYPES, ...URL_COLUMN_TYPES], column);
}

export const getDefaultDimensionField = (dimensionEntries, viewEntry) => {
  const defaultDimensionColumn = getDefaultDimensionColumn(viewEntry);
  const defaultDimension = _.find(dimensionEntries, { 'column':  defaultDimensionColumn});

  if (defaultDimensionColumn === 'Total') {
    return NONE_DIMENSION_FIELD;
  } else if (_.isEmpty(defaultDimension) || isGeoLocationOrUrlColumn(_.get(defaultDimension, 'column'))) {
    return _.get(dimensionEntries, [0, 'field']);
  } else {
    return _.get(defaultDimension, 'field');
  }
}

// This funtions only used for onBarClick function, only when tab order focus & enter.
export const getBarChartGroupAndDimension = (propData, currentSelectedIndex, chartType, isGroupByNone) => {
  const targetData =  _.get(propData, ['data', 0], []);
  if(chartType != 'groupChart' || isGroupByNone) {
    return { dimensionName: _.get(targetData, ['y', currentSelectedIndex]) };
  }
  return getStackedGroupAndDimension(propData, currentSelectedIndex);
}

// This funtions only used for onBarClick function, only when tab order focus & enter.
export const getStackedGroupAndDimension = (propData, currentSelectedIndex) => {
  const targetData =  _.get(propData, 'data');
  const dimensionConfigs = getBarDimensionConfigs(targetData);

  const customData = _.get(dimensionConfigs, [currentSelectedIndex, 'customdata']);
  const groupByValue = customData[0];
  const dimensionName = customData[1];
  return { groupByValue, dimensionName };
}

// This funtions only used for onBarClick function, only when tab order focus & enter.
export const getScatterPlotGroupAndDimension = (options) => {
  const { propData, currentSelectedIndex, chartType, showRange } = options;
  let scatterEntry, targetData;
  if(showRange) {
    targetData =  _.get(propData, ['data', currentSelectedIndex], {});
    scatterEntry = _.get(targetData, `meta.scatterEntry`);
  } else {
    targetData =  _.get(propData, ['data', 0], {});
    scatterEntry = _.get(targetData, `meta.${currentSelectedIndex}.scatterEntry`);
  }
  let dimensionName = _.get(scatterEntry, 'dimension');
  if(chartType != 'groupChart') {
    return { dimensionName }
  }
  const { dimension, group_by_field } = scatterEntry;
  let result = { dimensionName: dimension };
  return _.isEmpty(group_by_field) ? result : _.merge(result, { groupByValue: group_by_field });
}

export const getColorClassAndIconName = (viewEntry, percentage) => {
  const { upArrowColor, downArrowColor } = getArrowColors(viewEntry);
  let color, iconsClassName;

  if (percentage === 0) {
    color = DEFAULT_ARROW_COLOR;
    iconsClassName = '';
  } else if (percentage > 0) {
    color = upArrowColor;
    iconsClassName = 'icons-arrow-up2';
  } else {
    color = downArrowColor;
    iconsClassName = 'icons-arrow-down2';
  }

  return { color, iconsClassName };
}

export const getTargetEntryField = (viewEntry) => {
  const { visualization_type, column } = getTargetMetricEntry(viewEntry);
  const snapshotTypes = _.map(SNAPSHOT_VISUALIZATION_TYPES, 'type');
  const overtimeTypes = _.map(OVERTIME_VISUALIZATION_TYPES, 'type');
  const viewEntryField = _.get(viewEntry, 'field');
  let secondaryMetrics = [];

  if (_.includes(snapshotTypes, visualization_type)) {
    secondaryMetrics = _.get(
      viewEntry, `visualization.snapshot.${visualization_type}.secondary_metric_entries`
    );

  } else if (_.includes(overtimeTypes, visualization_type)) {
    secondaryMetrics = _.get(
      viewEntry, `visualization.overtime.${visualization_type}.secondary_metric_entries`
    );
  }

  const secondaryMetricField = _.chain(secondaryMetrics).find({ column }).get('field').value();
  return {
    targetEntryField: _.isEmpty(secondaryMetricField) ? viewEntryField : secondaryMetricField,
    isPrimaryMetric: _.isEmpty(secondaryMetricField)
  };
}

export const getAboveOrBelowEntry = (viewEntry, totalValue, newBenchmarkValue) => {
  const targetBenchMarkEnry = getTargetBenchMarkEntry(viewEntry);
  if (_.isEmpty(targetBenchMarkEnry)) {
    return {};
  }
  const nearTarget = _.get(viewEntry, 'near_target', {});
  const offTrack = _.get(viewEntry, 'off_track', {});
  const onTrack = _.get(viewEntry, 'on_track', {});
  const units = _.get(nearTarget, 'units', null);
  const benchmarkValue = Number(newBenchmarkValue) || Number(_.get(targetBenchMarkEnry, 'value', 0));
  const includeTargetValue = JSON.parse(_.get(onTrack, 'include_target_value', 'false'));
  const trackType = {
    [TARGET_ENTRY_TYPES.ABOVE]: onTrack.relative_direction === TARGET_ENTRY_TYPES.ABOVE ? onTrack : offTrack,
    [TARGET_ENTRY_TYPES.BELOW]: onTrack.relative_direction === TARGET_ENTRY_TYPES.BELOW ? onTrack : offTrack
  };
  let targetValue;

  if (units) {
    const percentageValue = (benchmarkValue * Number(units)) / 100;
    const startRange = (benchmarkValue - percentageValue);
    const endRange = (benchmarkValue + percentageValue)

    if (totalValue >= startRange && totalValue <= endRange) {
      targetValue = nearTarget;
    } else if (totalValue < startRange) {
      targetValue = trackType.Below;
    } else if (totalValue > endRange) {
      targetValue = trackType.Above;
    }
  } else {
    if (totalValue < benchmarkValue) {
      targetValue = trackType.Below;
    } else if (totalValue == benchmarkValue) {
      if (includeTargetValue) {
        targetValue = onTrack;
      } else {
        targetValue = offTrack;
      }
    } else if (totalValue > benchmarkValue) {
      targetValue = trackType.Above;
    }
  }

  return _.isEmpty(targetValue) ? {} :
    {
      ...targetValue,
      value: benchmarkValue,
      target_entry_description: targetValue.description
    };
}

export const getVisualizationTitle = (options) => {
  const {
    currentVisualizationType,
    currentDrilldownViewEntry,
    currentDrilldownDimensionField,
    currentSnapshotView,
    currentSecondaryMetricField,
    currentDrilldownTemplateId,
    currentDrilldownGroupByEntry
  } = options;

  const primaryMetricName = getPrimaryMetricName(currentDrilldownViewEntry);
  const dimensionName = getDimensionName(currentDrilldownTemplateId, currentDrilldownDimensionField);
  const secondaryMetricName = getSecondaryMetricNameForSnapshot(
    currentDrilldownViewEntry, currentVisualizationType, currentSnapshotView, currentSecondaryMetricField);
  const groupName = getGroupName(
    currentDrilldownGroupByEntry, currentSnapshotView, currentVisualizationType);
  return `${primaryMetricName}${secondaryMetricName}
    ${_.isEmpty(dimensionName) ? '' : `by ${dimensionName}`}
    ${groupName}`;
}

export const getArrowColors = (viewEntry) => {
  const upArrowColor = _.get(viewEntry, 'up_arrow_color') || DEFAULT_UP_OR_DOWN_ARROW_COLOR;
  const downArrowColor = _.get(viewEntry, 'down_arrow_color') || DEFAULT_UP_OR_DOWN_ARROW_COLOR;

  return { upArrowColor, downArrowColor };
}

export const getBenchmarkFilter = (benchmarkEntry, quickFilters) => {
  const { filter_column, filter_value } = benchmarkEntry;

  return _.filter(quickFilters, (filter) => {
    const filterConditions = _.get(filter, 'conditions', []);
    const condition = _.find(filterConditions, (condition) => _.get(condition, 'operator') === "=") || {};
    return (filter.column === filter_column && _.includes(condition.value, filter_value));
  });
}

export const getCurrentVizBasedChartType = (visType, visualizationState) => {

  if(_.isEqual(visType, VISUALIZATION_TYPES.SNAPSHOT.type)){
    return _.get(
      visualizationState, visType + '.currentSnapshotView', SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
  }else if(_.isEqual(visType, VISUALIZATION_TYPES.MAP.type)){
    return _.get(visualizationState, 'mapOptions.currentMap.type', DEFAULT_MAP_VIEW);
  }else if(_.isEqual(visType, VISUALIZATION_TYPES.OVERTIME.type)){
    return _.get(
      visualizationState, visType + '.currentChartView', OVERTIME_VISUALIZATION_TYPES.TIMELINE.type);
  } else if(_.includes([ VISUALIZATION_TYPES.TABLE.type,
    VISUALIZATION_TYPES.TIME_OF_DAY.type, VISUALIZATION_TYPES.DISTRIBUTION.type ], visType)){
    return visType
  } else {
    return null;
  }
};

export const getAvailableVisualizationTypes = (currentDrilldownTemplateId, viewEntry) => {
  let visualizationTypesToOmit = [];
  const isDisableDistributionTab = shouldDisableDistributionTab(viewEntry);

  if (shouldHideVisualizationButton(
    currentDrilldownTemplateId, viewEntry, VISUALIZATION_TYPES.SNAPSHOT.type)) {
    visualizationTypesToOmit.push(VISUALIZATION_TYPES.SNAPSHOT.type)
  }

  if (!isLocationsConfigured(currentDrilldownTemplateId) ||
     shouldHideVisualizationButton(currentDrilldownTemplateId, viewEntry, VISUALIZATION_TYPES.MAP.type)) {
    visualizationTypesToOmit.push(VISUALIZATION_TYPES.MAP.type)
  }
  if (!isDateColumnConfigured(currentDrilldownTemplateId) ||
     shouldHideVisualizationButton(
       currentDrilldownTemplateId, viewEntry, VISUALIZATION_TYPES.OVERTIME.type)) {
    visualizationTypesToOmit.push(VISUALIZATION_TYPES.OVERTIME.type)
  }
  if(isDisableDistributionTab || shouldHideVisualizationButton(
    currentDrilldownTemplateId, viewEntry, VISUALIZATION_TYPES.DISTRIBUTION.type)) {
    visualizationTypesToOmit.push(VISUALIZATION_TYPES.DISTRIBUTION.type)
  }

  if(disableTableVisualization(currentDrilldownTemplateId) || shouldHideVisualizationButton(
    currentDrilldownTemplateId, viewEntry, VISUALIZATION_TYPES.TABLE.type)){
    visualizationTypesToOmit.push(VISUALIZATION_TYPES.TABLE.type)
  }

  const visualizationTypes = getVisualizationTypes();
  return _.filter(visualizationTypes, (visualizationEntry) => {
    return !_.includes(visualizationTypesToOmit, visualizationEntry.type)
  })
}

export const getBenchmarkNames = (
  benchMarkEntries, benchMarkMetricNames, drilldownTemplateId, drilldownDimensionField
) => {
  const dimensionColumn = getCurrentDrilldownDimensionColumn(drilldownTemplateId, drilldownDimensionField);
  const  dimensionBenchMarkEntries = _.filter(benchMarkEntries, (entry) => {
     return (entry.dimension_column === dimensionColumn || _.isEmpty(entry.dimension_column))
  });

  const benchmarkNames = _.compact(_.map(dimensionBenchMarkEntries, (benchmark) =>{
    if(_.includes(benchMarkMetricNames, benchmark.name)){
      return benchmark.name;
    }
  }));

  return benchmarkNames;
}

export const getCurrentSecondaryMetricFields = (
  viewEntry,
  currentChartView,
  secondaryMetricField,
  currentVisType,
  commonFilters,
  currentVizBasedChartType
) => {
  const isComboChart = isComboChartEnable(viewEntry, currentChartView);
  const isOvertimeChart = (currentVisType === _.get(VISUALIZATION_TYPES, ['OVERTIME', 'type']));

  if (isComboChart && isOvertimeChart) {
    const secondaryMetricEntries = getSecondaryMetricEntries(viewEntry, currentVisType, currentChartView);

    return _.chain(secondaryMetricEntries).
             map((secondaryMetric) => {
              const { field } = secondaryMetric;
              return { ...secondaryMetric, field, isComparePrevious: false, secondaryDateRange: '' };
             }).
             compact().
             value();
  }

  if (_.isEmpty(secondaryMetricField)) {
    return [];
  }
  const secondaryMetricEntry = getSecondaryMetricEntry(
    viewEntry, currentVisType, currentVizBasedChartType, secondaryMetricField
  );
  const isStackedChart = isStackTypeChart({ secondaryMetricEntry });
  if (isStackedChart) {
    return [];
  }
  const isComparePrevious = !_.isEmpty(_.find(COMPARISON_MODE_OPTIONS, { name: secondaryMetricField }));
  const secondaryDateRange = isComparePrevious ?
    getComparisonPeriodDateRanges({
      ...commonFilters,
      comparisonType: secondaryMetricField
    })[0] : '';

  return [{
    ...secondaryMetricEntry,
    field: isComparePrevious? _.get(viewEntry, 'field') : secondaryMetricField,
    isComparePrevious,
    secondaryDateRange
  }];
}

export const isCurrencyType = ({ currentDrilldownTemplateId, currentDrilldownDimensionField }) => {
  const currentDrilldownDimension = getSelectedDimensionEntry(
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  );
  const dimensionRenderType = _.get(currentDrilldownDimension, 'renderType');

  return (dimensionRenderType === CURRENCY_TYPE);
}

export const isGroupByCurrencyType = (groupByEntry) => {
  return _.get(groupByEntry, 'renderType') === CURRENCY_TYPE;
}

export const getSecondsAsDurationHours = (value, dataUnit = 'seconds') => {
  if(_.isUndefined(value)) {
    return null;
  }
  const momentData = moment.duration(value, dataUnit);
  return _.floor(momentData.asHours(), 2);
}

export const getSecondsAsDuration1 = (value, dataUnit = 'seconds') => {
  if(_.isUndefined(value)) {
    return null;
  }
  const momentData = moment.duration(value, dataUnit);
  let hours = momentData.hours();
  let minutes = momentData.minutes();
  let seconds = momentData.seconds();

  if(hours < 10) { hours = "0" + hours;}
  if(minutes < 10) { minutes = "0" + minutes;}
  if(seconds < 10) { seconds = "0" + seconds;}

  return hours + ':' + minutes + ':' + seconds;
}

export const getSecondsAsDuration = (value, dataUnit = 'seconds') => {
  if(_.isUndefined(value)) {
    return '-';
  }
  const duration = moment.duration(value, dataUnit);
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) % 60;
  const remainingSeconds = Math.floor(duration.asSeconds()) % 60;

  const hourText = hours.toString().padStart(2, '0');
  const minuteText = minutes.toString().padStart(2, '0');
  const secondText = remainingSeconds.toString().padStart(2, '0');

  return `${hourText}:${minuteText}:${secondText}`;
}


// export const getSecondsAsNumericTime = (value, dataUnit = 'seconds') => {
//   if(_.isUndefined(value)) {
//     return getMinSecondsAsNumeric();
//   }
//   // for plotly to understand it as a date moment().format("YYYY-MM-DD")
//   const stringDateTime = addNumericTimeValueToDate("1970-01-01", value, dataUnit);
//   const dateNumericValue = new Date(stringDateTime).valueOf();
//   return dateNumericValue;
// }

export const getSecondsAsNumericTime = (value, dataUnit = 'seconds') => {
  if (_.isUndefined(value)) {
    return getMinSecondsAsNumeric();  }
  if (dataUnit === 'seconds') {
  const numericValue = parseFloat(value);
  const scaledValue = (numericValue / 100) * 0.01;
  return scaledValue;
  } else {
    const stringDateTime = addNumericTimeValueToDate("1970-01-01", value, dataUnit);
    const dateNumericValue = new Date(stringDateTime).valueOf();
    return dateNumericValue;
  }
};


export const getMinSecondsAsNumeric = () => {
  // for plotly to understand it as a date
  const stringDateTime = "1970-01-01 00:00:00";
  const dateNumericValue =  new Date(stringDateTime).valueOf();
  return dateNumericValue;
}

export const addNumericTimeValueToDate = (dateValue, timeValue , unit = 'seconds') => {
  var timestamp = Date.parse(dateValue);

  if(_.isEmpty(timeValue)) timeValue=0;

  if (unit === "minutes") {
    timeValue *= 60;
  }
  var timeInMilliseconds = timeValue * 1000;
  var newTimestamp = timestamp + timeInMilliseconds;
  var newDate = new Date(newTimestamp);
  var formattedDate = newDate.toISOString().slice(0, 10);
  var formattedTime = newDate.toISOString().slice(11, 19);
  return formattedDate + ' ' + formattedTime;
}

export const getMillisecondsAsDateTime = (dateTime) => {
  const dateNumericValue =  new Date(dateTime).valueOf();
  return dateNumericValue;
}

export const handleBackButtonClick = (history) => {
  const hash = '/';
  history.push(hash);
}

export const updateCardLoadingStatusForImageGenerator = (cardImageId, loadingStatus) => {
  // Setting window object to check whether all cards are loaded the charts or not
  // from the image_generator
  if(_.isUndefined(window.allCardsLoadingStatus) && !_.isEmpty(cardImageId)){
    window.allCardsLoadingStatus = {};
    window.allCardsLoadingStatus[cardImageId] = loadingStatus;
  } else if (!_.isUndefined(window.allCardsLoadingStatus[cardImageId])) {
     window.allCardsLoadingStatus[cardImageId] = loadingStatus;
  }
}

export const updateBookMarkCardLoadingStatusForImageGenerator = (loadingStatus) => {
  // Setting window object to check whether visualization loaded the charts or not
  // from the image_generator
  if(_.isEmpty(window.bookMarkCardLoadingStatus)){
    window.bookMarkCardLoadingStatus = true;
  }
  window.bookMarkCardLoadingStatus = loadingStatus;
}

export const getNearestRoundOffValue = (value, metricEntry) => {
  if(_.isUndefined(value)) {
    return null;
  }
  const displayValueAs = _.get(metricEntry, 'display_value_as', undefined);
  const dataUnit = getRoundOffUnit(displayValueAs);
  if(_.isUndefined(dataUnit)){
    return value;
  }
  return getRoundOffValue(value, dataUnit);
}

export const getRoundOffValue = (value, roundOffUnit) => {
  const roundOffValue =  Math.round(value / roundOffUnit) * roundOffUnit;
  return roundOffValue;
}

export const getRoundOffUnit = (displayValue) => {
  const unitValue = {
    'nearest-hundred': 100 ,
    'nearest-thousand': 1000
  }
  return _.get(unitValue, displayValue, undefined);
}

export const canRenderDrilldownDimension = (currentVisualizationType) => {
  const visualization_list = [VISUALIZATION_TYPES.SNAPSHOT.type,
    VISUALIZATION_TYPES.OVERTIME.type,
    VISUALIZATION_TYPES.DISTRIBUTION.type,
    VISUALIZATION_TYPES.TIME_OF_DAY.type
  ];
  return _.includes(visualization_list,currentVisualizationType);
}

export const canRenderGroupByDropdown = (currentVisualizationType) => {
  return(
    _.isEqual(currentVisualizationType, VISUALIZATION_TYPES.SNAPSHOT.type)
  )
}

const getMatchedCustomSortOptions = (templateEntry, entry = {}) => {
  let newCustomSortOptions = [];
  const customSortOptions = _.filter(templateEntry['custom_sort_order_entries'], (option) => {
    return (
      _.isEqual(option['column'], entry['column']) &&
      _.isEqual(option['column_dataset']||'', entry['column_dataset']||'')
    )
  });

  if(customSortOptions.length > 0){
    newCustomSortOptions = _.map(customSortOptions, (option) => {
      const orderValues = option['orderValues'] || [];
      return {
        name: option['name'],
        sortBy: option['field'],
        sortOrder: option['order'],
        type: option['field'],
        isCustomSort: true,
        orderValues: option['order'] == 'desc' ? _.reverse(orderValues) : orderValues
      }
    })
  }
  return newCustomSortOptions;
}

export const allowLeafPageOnTableClickByTemplate = (templateEntry) => {
  if (allowLeafPageOnTableClick()){
    return true;
  }else{
    const allowLeafPageOnTemplate =
      _.get(templateEntry, 'leaf_page.enable_table_record_to_leaf_page', 'false');
    return allowLeafPageOnTemplate === 'true'
  }
}
