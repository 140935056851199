import React, { useEffect, useRef } from 'react'
import { ForgePopup } from '@tylertech/forge-react'
import { withCardClickHandler } from 'pages/dashboard/components/CardList/CardClickHandler'
import ModalHeader from './ModalHeader/Index';
import ModalBody from './ModalBody/Index';
import ModalFooter from './ModalFooter/Index';
import PropTypes from 'prop-types'
import { CARD_TYPES } from 'appConstants';
import { getDrilldownEntryParams } from 'pages/dashboard/components/TemplateCards/TemplateCard';
import { useSelector } from 'react-redux';
import { isClickOutSideFilterPopup } from 'common/components/Filters/FilterDomHelper';

function MetricCardQuickViewModal(props) {
  const {
    isQuickViewModalOpen,
    onModalStateChange,
    metricCardEntry,
    targetPopupElement,
    metricLibraryOption,
    setIsCardsLoading,
    onCardClick,
    onSavedViewDelete
  } = props;
  const { placeCardIntoCollection, removeCardFromCollection } = metricLibraryOption;
  const { templateEntry, viewEntry } = metricCardEntry;
  const drillDownEntry = getDrilldownEntryParams(templateEntry, viewEntry);
  const commonFilters = useSelector(state => _.get(state, 'commonFilters', {}));
  let targetPopupRef = useRef(targetPopupElement);
  const viewModalRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  },[]);

  useEffect(() => {
    if (isQuickViewModalOpen) {
      const metricWithDrillDownParams = {
        ...metricCardEntry,
        drilldownEntry: drillDownEntry,
        commonFilters
      }
      const { userCardEntry } = metricCardEntry;
      const { isBookmarkCard } = userCardEntry;
      setTimeout(() => {
        // To avoid the modal open delay we are delaying the card click event
        isBookmarkCard ?
        onCardClick(CARD_TYPES.BOOKMARK, userCardEntry, commonFilters, true)
        : onCardClick(CARD_TYPES.TEMPLATE, metricWithDrillDownParams, {}, true);
      }, 100);
    }
  }, [targetPopupRef]);

  useEffect(() => {
    if (viewModalRef.current) {
      viewModalRef.current.focus();
    }
  },[viewModalRef.current])

  const handleClickOutside = (e) => {
    const closestClassNames = ['.more-icon', '.forge-button__ripple', '.metric-card-quick-view-modal'];

    if(isClickOutSideFilterPopup(e, _.get(targetPopupRef, 'current'), closestClassNames)) {
      toggleModalOpen(false);
    }
  }

  const toggleModalOpen = (isOpen) => {
    onModalStateChange(isOpen);
  };

  const onPlaceMetricCard = () => {
    placeCardIntoCollection(metricCardEntry);
    toggleModalOpen(false);
  }

  const onDeleteMetricCard = (savedViewId) => {
    removeCardFromCollection(metricCardEntry);
    onSavedViewDelete(savedViewId)
    toggleModalOpen(false);
  }

  const forgePopupOptions = {
    placement: 'left',
    offset: { x: 50, y: 0 }
  }

  return (
    <ForgePopup open={isQuickViewModalOpen}
      targetElementRef={targetPopupRef}
      options={forgePopupOptions}>
      <div className='metric-card-quick-view-modal' tabIndex={0} ref={viewModalRef}>
        <ModalHeader toggleModalOpen={toggleModalOpen}
          metricCardEntry={metricCardEntry}
          onDeleteMetricCard={onDeleteMetricCard}
          setIsCardsLoading={setIsCardsLoading}/>
        <ModalBody metricCardEntry={metricCardEntry} />
        <ModalFooter toggleModalOpen={toggleModalOpen}
          metricCardEntry={metricCardEntry}
          onPlaceClick={onPlaceMetricCard}
        />
      </div>
    </ForgePopup>
  )
}

MetricCardQuickViewModal.propTypes = {
  isQuickViewModalOpen: PropTypes.bool,
  onModalStateChange: PropTypes.func,
  metricCardEntry: PropTypes.object,
  targetPopupElement: PropTypes.object,
  onPlaceClick: PropTypes.func,
  onCardClick: PropTypes.func,
  metricLibraryOption: PropTypes.object,
  setIsCardsLoading: PropTypes.func,
  onSavedViewDelete: PropTypes.func
}

export default withCardClickHandler(MetricCardQuickViewModal)
