import React from 'react'
import PropTypes from 'prop-types'
import { getAvailableVisualizationType } from 'helpers/visualizationHelper';
import { ForgeIcon, ForgeTooltip } from '@tylertech/forge-react';
import { METRIC_CHART_ICONS, VISUALIZATION_TYPES } from 'appConstants';

function MetricIcon(props) {
  const {
    templateEntry,
    viewEntry,
    isCardExist,
    bookmarkOptions,
    isBookmarkCard
  } = props;

  const existingCardIcon = () => {
    return (<>
      <img className='forge-metric-icon' alt='check' src="../images/check-icon.svg" />
      <ForgeTooltip delay="500" position="top">
        This metric is already in the collection.
      </ForgeTooltip>
    </>)
  }

  const renderForgeChartIcon = (metricVizType) => {
    if (VISUALIZATION_TYPES.TABLE.type === metricVizType) {
      return <ForgeIcon className="forge-metric-icon" name="table" />
    } else if (VISUALIZATION_TYPES.DISTRIBUTION.type === metricVizType) {
      return <ForgeIcon className="forge-metric-icon" name="bar_chart" />
    } else if (VISUALIZATION_TYPES.TIME_OF_DAY.type === metricVizType) {
      return <ForgeIcon className="forge-metric-icon" name="timer" />
    }
  }

  const savedViewCardIcon = () => {
    const visualizationType = _.get(bookmarkOptions, 'currentVizChartType', 'barchart');

    if(_.includes([ VISUALIZATION_TYPES.TABLE.type, VISUALIZATION_TYPES.DISTRIBUTION.type,
      VISUALIZATION_TYPES.TIME_OF_DAY.type
      ], visualizationType)){
      return renderForgeChartIcon(visualizationType);
     } else {
      const chartIconEntry = _.find(METRIC_CHART_ICONS, { chartType: visualizationType });
      const iconName = _.get(chartIconEntry, 'icon', '');

      return <i className={`forge-metric-icon ${iconName}`}></i>
    }
  }

  const renderMetricCardIcon = () => {
    const visualizationType = getAvailableVisualizationType(
      _.get(templateEntry, 'template_id'),
      _.get(viewEntry, 'visualization.default_view'),
      viewEntry
    );

    if(_.includes([ VISUALIZATION_TYPES.TABLE.type, VISUALIZATION_TYPES.DISTRIBUTION.type,
      VISUALIZATION_TYPES.TIME_OF_DAY.type
    ], visualizationType)){
      return renderForgeChartIcon(visualizationType);
    } else if (
      _.includes([VISUALIZATION_TYPES.OVERTIME.type,
        VISUALIZATION_TYPES.SNAPSHOT.type,
        VISUALIZATION_TYPES.MAP.type
      ], visualizationType)) {

      const viewChartType = _.get(viewEntry, `visualization.${visualizationType}.default_view`, '');
      const defaultIconType = _.find(METRIC_CHART_ICONS, { chartType: viewChartType });
      const iconName = _.get(defaultIconType, 'icon', '');

      return <i className={`forge-metric-icon ${iconName}`}></i>
    } else {
      return <ForgeIcon className="forge-metric-icon" name="icons-chart-bar-horizontal" />
    }
  }

  const renderIcons = () => {
    if (isCardExist) {
      return existingCardIcon();
    }
    if(isBookmarkCard){
      return savedViewCardIcon();
    }

    return renderMetricCardIcon();
  }

  return renderIcons()
}

MetricIcon.propTypes = {
  templateEntry: PropTypes.object,
  viewEntry: PropTypes.object,
  isCardExist: PropTypes.bool
}

export default MetricIcon
