import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { getCardId } from '../collectionHelper';
import CardDrag from '../../CardList/CardDrag';
import { ForgeCard } from '@tylertech/forge-react';
import MetricIcon from './MetricIcon';
import { getUniqueKey } from './metricLibraryHelper';
import { LIBRARY_TYPE } from 'appConstants';
import EmptyBookmarkText from '../../CardList/EmptyBookmarkText';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import MetricCardQuickViewModal from './MetricCardQuickViewModal/Index';
import LoadingSpinner from 'common/components/LoadingSpinner';
import useDragCheck from 'common/Hooks/useDragCheck';

const MetricResultNoData = lazy(() => import('./MetricResultNoData'));
function MetricLibraryCards({
  metricLibraryOption, libraryType, metricCardEntries, onSavedViewDelete
}) {

  const libraryCardRefs = useRef([])
  const { userCards, bookmarkEntries } = metricLibraryOption;
  const [isCardsLoading, setIsCardsLoading] = useState(false);
  const {
    onMouseDown: onMouseClick,
    canCallClickEvent,
    setCanCallClickEvent,
    eventData: CardClickParams
  } = useDragCheck();

  const isBookmarkCard = libraryType === LIBRARY_TYPE.MY_SAVED_VIEWS;
  const [selectedCardKey, setSelectedCardKey] = useState('');
  const [metricCardEntry, setMetricCardEntry] = useState({});
  const [ isQuickViewModalOpen, setIsQuickViewModalOpen ] = useState(false);

  useEffect(() => {
    if(canCallClickEvent){
      const { uniqueKey, cardProps } = CardClickParams;
      onCardClick(uniqueKey, cardProps);
    }
  }, [canCallClickEvent]);

  const onCardClick = (uniqueKey, cardProps) => {
    setTimeout(() => {
      setSelectedCardKey(uniqueKey);
      setMetricCardEntry(cardProps);
      setIsQuickViewModalOpen(true);
    }, 100);
  }

  const onEnterCardEntry = (e, uniqueKey, cardProps) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      onCardClick(uniqueKey, cardProps);
    }
  }

  const onModalStateChange = (modalState) => {
    libraryCardRefs.current[selectedCardKey].focus();
    if (!modalState) {
      setIsQuickViewModalOpen(false);
      setSelectedCardKey('');
      setCanCallClickEvent(false);
    }
  }

  const renderQuickViewModal = () => {
    if(!isQuickViewModalOpen){
      return null;
    }
    return (
      <MetricCardQuickViewModal key={selectedCardKey}
        isQuickViewModalOpen={isQuickViewModalOpen}
        targetPopupElement={libraryCardRefs.current[selectedCardKey]}
        onModalStateChange={onModalStateChange}
        metricCardEntry={metricCardEntry}
        metricLibraryOption={metricLibraryOption}
        setIsCardsLoading={setIsCardsLoading}
        onSavedViewDelete={onSavedViewDelete}
      />
    );
  }

  const renderMetricIcon = (metricCardEntry, isCardExist) => {
    const { templateEntry, viewEntry, bookmarkOptions } = metricCardEntry;

    return(
      <MetricIcon
        templateEntry={templateEntry}
        viewEntry={viewEntry}
        isCardExist={isCardExist}
        bookmarkOptions={bookmarkOptions}
        isBookmarkCard={isBookmarkCard}
      />
    );
  }

  const renderMetricCard = (metricCardEntry, uniqueKey) => {
    const { templateEntry, viewEntry, bookmarkOptions } = metricCardEntry;
    const viewName = isBookmarkCard ? _.get(metricCardEntry, 'name', '') : _.get(viewEntry, 'name', '');
    const templateName = _.get(templateEntry, 'name', '');
    const cardDescription = isBookmarkCard ?
      _.get(bookmarkOptions, 'description', '') : _.get(viewEntry, 'view_description', '');

    const cardId = isBookmarkCard ? _.toString(_.get(metricCardEntry, 'id', '')) :
      getCardId(templateEntry, viewEntry);
    const isCardExist = _.some(userCards, (card) => {
      return _.isEqual(_.toString(card?.card_id), cardId );
    });
    const matchedCard = _.find(userCards, (card) =>{
      return _.isEqual(_.toString(card?.card_id), cardId );
    });
    let cardBgClass = selectedCardKey == uniqueKey ? 'metric-card-active-color' : '';
    cardBgClass = isCardExist ? 'metric-card-color' : cardBgClass;
    const collectionCardId = _.get(matchedCard, 'id', null);
    // For Deleting card from collection
    const customCardEntry = {
      cardEntry: { id: collectionCardId },
      id: collectionCardId,
    }

    const cardProps = {
      userCardEntry: {...customCardEntry, ..._.merge(metricCardEntry, { isBookmarkCard })},
      viewEntry,
      templateEntry,
      isMetricLibrary: true,
      customClass: 'card-drag',
      isCardExist
    };
    const className = `cursor-pointer ${uniqueKey}`;
    return (
      <CardDrag {...cardProps} key={`${uniqueKey}-drag`} >
        <div className={className}
          onMouseDown={(e) => onMouseClick(e, {uniqueKey, cardProps})}
          onKeyDown={(e) => onEnterCardEntry(e, uniqueKey, cardProps)} tabIndex={0}
          ref={el => libraryCardRefs.current[uniqueKey] = el} >
          <ForgeCard key={uniqueKey} className={cardBgClass}
            onClick={() => setSelectedCardKey(uniqueKey)}>
            <div className="d-flex align-items-center gap-10">
              {renderMetricIcon(metricCardEntry, isCardExist)}
              <div className='d-flex flex-column gap-5 overflow-hidden'>
                <div className='metric-card-title'>{viewName}</div>
                { !isBookmarkCard && <div className='metric-card-subtitle'>{templateName}</div>}
                <div className='metric-card-description'>{cardDescription}</div>
              </div>
            </div>
          </ForgeCard>
        </div>
      </CardDrag>
    )
  }

  const renderNoDataSearchMetric = () => {
    return (
      <Suspense>
        <MetricResultNoData />
      </Suspense>
    )
  }

  const renderNoDataForBookmarks = () => {
    return (
      <EmptyBookmarkText overrideClass='library-no-data' />
    )
  }

  const renderMetricCards = () => {
    if (isBookmarkCard && _.isEmpty(bookmarkEntries)) {
      return renderNoDataForBookmarks();
    } else if (_.isEmpty(metricCardEntries)) {
      return renderNoDataSearchMetric();
    }

    return _.map(metricCardEntries, (cardEntry, index) => {
      return renderMetricCard(cardEntry, getUniqueKey(cardEntry, index));
    });
  }

  const renderSpinner = () => {
    return <LoadingSpinner isLoading={isCardsLoading} />;
  }

  return (
    <div className='metric-card-list mt-2 pb-1'>
      {renderMetricCards()}
      {renderQuickViewModal()}
      {renderSpinner()}
    </div>
  )
}

MetricLibraryCards.propTypes = {
  metricLibraryOption: PropTypes.object,
  libraryType: PropTypes.string,
  metricCardEntries: PropTypes.array,
  placeCardIntoCollection: PropTypes.func,
  onSavedViewDelete: PropTypes.func,

}

export default MetricLibraryCards
