import _ from 'lodash';
import qs from 'qs';

import {
  getDefaultRadarAreaEntry,
  getRadarAreaEntryById,
  getSelectedRadarMetricEntriesByIds,
  getSelectedRadarMapMetricById,
  getDefaultRadarMetricFilters,
  getAdvanceSearchTemplateId
} from 'common/config/customerConfiguration';
import { relativeDateFilterEntryDefaultState } from 'helpers/commonFiltersHelper';
import { getCompareSamePeriodLastYearOptions } from 'helpers/fiscalPeriodUtils';
import {
  getDefaultShapeDatasetEntry,
  getCurrentTemplateEntryById,
  getCurrentTemplateEntryByName,
  getViewEntries,
  getQuickFilterEntries,
  getGroupByEntries,
  getDimensionEntries
} from 'common/config/templateConfiguration';

import {
  getSelectedMapStyleEntry,
  getSelectedMapViewEntry,
  getSelectedOutlineEntries
} from 'modules/Map/helpers/MapOptionsHelper';
import { mapOptionsReducerDefaultState } from 'reducers/mapOptionsReducer';
import { visualizationReducerDefaultState } from 'reducers/helper';
import { getCurrentPeriodDateRangeFromState, getSelectedYearsForBienniumYear } from 'helpers/dateHelper';
import { COLLECTION_ID, SHAPE_AREA_TABS } from 'appConstants';
import { getCardId } from 'pages/embed/helper';
import { getCollectionId } from './collectionIdHelper';
import {
  getMapCanvasStateToUrl,
  getMapCanvasStateFromUrl
} from './MapCanvas/UrlParamsHelper';

export const updateStoreToUrlForAdvanceSearch = (initialHashString, state) => {
  let hashString = initialHashString;
  window.defaultState = state;
  let stateWithStoreValues = {};
  const searchField = encodeURIComponent(JSON.stringify(_.get(state, 'advanceSearch.searchField', '')));
  const templateId = _.get(state, 'advanceSearch.templateId', getAdvanceSearchTemplateId());
  const selectedShapeIds =  encodeURIComponent(JSON.stringify(
    _.get(state, 'advanceSearch.selectedShapeIds', [])));
  const filters = encodeURIComponent(JSON.stringify(_.get(state, 'advanceSearch.filters', [])));
  const shapeDatasetEntry = encodeURIComponent(
    JSON.stringify(_.get(state, 'advanceSearch.shapeDatasetEntry', getDefaultShapeDatasetEntry(templateId)))
  );
  const subjectRowId = _.get(state, 'advanceSearch.subjectRowId', '');
  const searchPage = _.get(state, 'advanceSearch.searchPage', '');
  const selectedReportRowIds = encodeURIComponent(JSON.stringify(
    _.get(state, 'advanceSearch.selectedReportRowIds', [])));
  const sortColumns = encodeURIComponent(JSON.stringify(_.get(state, 'advanceSearch.sortColumns', [])));
  const searchProperty = encodeURIComponent(JSON.stringify(_.get(state, 'advanceSearch.searchProperty', {})));
  const filteredGeojson = encodeURIComponent(
    JSON.stringify(_.get(state, 'visualization.mapOptions.filteredGeojson', {}))
  );
  const currentBookmarkId = _.get(state, 'bookmark.currentBookmarkId', '');
  const isComparisonListOpen = _.get(state, 'advanceSearch.isComparisonListOpen');

  stateWithStoreValues = {
    ...stateWithStoreValues,
    searchField,
    filters,
    selectedShapeIds,
    templateId,
    subjectRowId,
    selectedReportRowIds,
    shapeDatasetEntry,
    searchPage,
    sortColumns,
    searchProperty,
    filteredGeojson,
    currentBookmarkId,
    isComparisonListOpen
  };

  return hashString + qs.stringify(stateWithStoreValues, {
    arrayFormat: 'repeat', addQueryPrefix: true, encode: false
  });
}

export const updateStoreToUrl = (initialHashString, state) => {
  let hashString = initialHashString;
  let stateWithStoreValues = {};
  window.defaultState = state;
  const dashboardState = _.get(state, 'dashboard', {});
  const dashboard = _.isEmpty(dashboardState) ? ''
    : encodeURIComponent(JSON.stringify(
      _.omit(dashboardState, [
        'manageCollection',
        'minDatesForTemplateEntries',
        'defaultTemplateId',
        'defaultViewId'
      ])
    ));
  const dateRange = _.get(state, 'commonFilters.dateRange');
  const comparisonType = _.get(state, 'commonFilters.comparisonType');
  const dashboardComparisonType = _.get(state, 'commonFilters.dashboardComparisonType');
  const comparisonModeOn = _.get(state, 'commonFilters.comparisonModeOn');
  const dateType = _.get(state, 'commonFilters.dateType', '');
  const relativeDateFilterEntry = _.get(state, 'commonFilters.relativeDateFilterEntry');
  const additionalDateFilters = _.get(state, 'commonFilters.additionalDateFilters');
  const collectionDateFilters = _.get(state, 'commonFilters.collectionDateFilters');
  const templateName = _.get(state, 'drilldown.currentDrilldownTemplateName', '')
  const templateId = _.get(state, 'drilldown.currentDrilldownTemplateId', '')
  const currentDrilldownTemplateName =  _.isEmpty(templateName) ?
    templateName : encodeURIComponent(templateName);
  const currentDrilldownTemplateId =  _.isEmpty(templateId) ?
    templateId : encodeURIComponent(templateId);
  const currentDrilldownDimensionField = _.get(state, 'drilldown.currentDrilldownDimensionField');
  const currentVisualizationType = _.get(state, 'drilldown.currentVisualizationType', '');
  const isTimeOfDayOn = _.get(state, 'drilldown.isTimeOfDayOn', false);
  const timeOfDayFilters = encodeURIComponent(JSON.stringify(_.get(state, 'drilldown.timeOfDayFilters', [])));
  const currentDrilldownGroupByField = _.get(state, 'drilldown.currentDrilldownGroupByEntry.field', '');
  const currentDrilldownViewField = _.get(state, 'drilldown.currentDrilldownViewEntry.field', '');
  const center = _.get(state, 'visualization.mapOptions.centerAndZoom.center', []);
  const zoom = _.get(state, 'visualization.mapOptions.centerAndZoom.zoom', {});
  const shapeDatasetEntryId = _.get(state, 'visualization.mapOptions.shape.datasetEntry.id', '');
  let geoCoder = _.get(state, 'visualization.mapOptions.geoCoder', '');

  if(!_.isEmpty(zoom)){
    geoCoder = _.merge({}, _.get(state, 'visualization.mapOptions.geoCoder', {}), { zoom });
  }

  let comparisonDateRanges = _.get(state, 'commonFilters.comparisonDateRanges', []);
  comparisonDateRanges = _.isEmpty(comparisonDateRanges) ?
    '' : encodeURIComponent(JSON.stringify(comparisonDateRanges));
  let dashboardComparisonDateRanges = _.get(state, 'commonFilters.dashboardComparisonDateRanges', []);
  dashboardComparisonDateRanges = _.isEmpty(dashboardComparisonDateRanges) ?
      '' : encodeURIComponent(JSON.stringify(dashboardComparisonDateRanges));
  let shapeIds = _.get(state, 'visualization.mapOptions.shape.selectedShapeIds', []);
  shapeIds = _.isEmpty(shapeIds) ? '' : encodeURIComponent(JSON.stringify(shapeIds));
  const filteredGeojson = encodeURIComponent(JSON.stringify(
    _.get(state, 'visualization.mapOptions.filteredGeojson', {})));
  const quickFilters = _.get(state, 'drilldown.quickFilters', []);
  const newQuickFilters = _.isEmpty(quickFilters)  ? '' : encodeURIComponent(JSON.stringify(quickFilters));
  const sortColumns = _.isEmpty(_.get(state, 'visualization.table.sortColumns', [])) ? ''
    : encodeURIComponent(JSON.stringify(_.get(state, 'visualization.table.sortColumns')));
  const isLeafPage = _.get(state, 'visualization.table.isLeafPage');
  const isTableView = _.get(state, 'visualization.table.isTableView');
  const leafPageCurrentRecordIndex = _.get(state, 'visualization.table.leafPageCurrentRecordIndex');
  const currentLeafData = _.isEmpty(_.get(state, 'visualization.table.currentLeafData')) ? ""
    : encodeURIComponent(JSON.stringify(_.get(state, 'visualization.table.currentLeafData')));
  // Adding current visualization details only
  const visualization = encodeURIComponent(JSON.stringify(
    _getCurrentVisualizationConfigs(state, currentVisualizationType)));
  const drilledDownDimensions = _.isEmpty(_.get(state, 'drilldown.drilledDownDimensions', [])) ? ''
    : encodeURIComponent(JSON.stringify(_.get(state, 'drilldown.drilledDownDimensions')));

  const currentMapView = _.get(state, 'visualization.mapOptions.currentMapView.type', '');
  const currentMapStyle = _.get(state, 'visualization.mapOptions.currentMapStyleEntry.name', '');
  const outlines = _.chain(_.get(state, 'visualization.mapOptions.selectedOutlines'))
    .map(function(outline) {
      return _.get(outline, 'shape_outline_dataset_id');
    })
    .join(',')
    .value();
  const currentBookmarkId = _.get(state, 'bookmark.currentBookmarkId');
  const currentBookmarkType= _.get(state, 'bookmark.currentBookmarkType', '');
  const insightId = _.get(state, 'insight.insightId', '');
  const managePageCollectionId = _.get(state, 'manage.collectionId');
  const subscriptionId = _.get(state, 'manage.subscriptionId');

  const currentRadarDateRange = _.get(state, 'radar.currentDateRangeOptions.dateRange');
  const currentRadarDateType = _.get(state, 'radar.currentDateRangeOptions.dateType');
  const radarRelativeDateFilterEntry = _.get(
    state, 'radar.currentDateRangeOptions.relativeDateFilterEntry', {}
  )
  const currentRelativeDateFilterEntry = _.isEmpty(radarRelativeDateFilterEntry) ? "" :
    encodeURIComponent(
      JSON.stringify(_.get(state, 'radar.currentDateRangeOptions.relativeDateFilterEntry'), {})
    );

  const previousRadarDateRange = _.get(state, 'radar.previousDateRangeOptions.dateRange');
  const previousRadarDateType = _.get(state, 'radar.previousDateRangeOptions.dateType');
  const previousRadarDateComparisonPeriod = _.isEmpty(_.get(state,
    'radar.previousDateRangeOptions.comparisonPeriod', {})) ? '' :
    _.get(state, 'radar.previousDateRangeOptions.comparisonPeriod', {});

  const selectedShapeAreaEntryId = _.get(state, 'radar.selectedShapeAreaEntry.id');

  const selectedRadarTracts = _.isEmpty(_.get(state, 'radar.selectedTracts', [])) ? ''
    : encodeURIComponent(JSON.stringify(_.get(state, 'radar.selectedTracts')));
  const comparisonTracts = _.isEmpty(_.get(state, 'radar.comparisonTracts', [])) ? ''
    : encodeURIComponent(JSON.stringify(_.get(state, 'radar.comparisonTracts')));

  const isCheckedComparisonArea = _.get(state, 'radar.isCheckedComparisonArea');
  const isCheckedUnSelectedArea = _.get(state, 'radar.isCheckedUnSelectedArea');
  const selectAreaName = _.get(state, 'radar.selectAreaName');
  const comparisonAreaName = _.get(state, 'radar.comparisonAreaName');

  const selectedRadarMetricEntries =  _.get(state, 'radar.selectedMetricEntries', []);
  const selectedRadarMetricIds = _.isEmpty(selectedRadarMetricEntries) ? ''
    : encodeURIComponent(JSON.stringify(_.map(selectedRadarMetricEntries, 'id')));

  const selectedRadarMapMetricId =  _.get(state, 'radar.selectedMapMetric.id', '');

  let radarMetricGlobalFilters =  _.get(state, 'radar.metricGlobalFilters', []);
  radarMetricGlobalFilters = _.isEmpty(radarMetricGlobalFilters) ? ''
    : encodeURIComponent(JSON.stringify(radarMetricGlobalFilters));

  const radarMetricFilters =  _.get(state, 'radar.metricFilters', []);
  const newRadarMetricFilters = _.isEmpty(radarMetricFilters) ? ''
    : encodeURIComponent(JSON.stringify(radarMetricFilters));

  const radarBookmarkId = _.get(state, 'radar.bookmarkId');


  // forecast
  let selectedForecastMetric = _.get(state, 'forecasting.selectedForecastMetric');
  const selectedForecastTemplateId = _.get(state, 'forecasting.selectedForecastTemplateId');
  const axisGranularity = _.get(state, 'forecasting.axisGranularity');

  const forecastBookmarkId = _.get(state, 'forecasting.bookmarkId');
  const currentForecastPage = _.get(state, 'forecasting.currentForecastPage');
  // selectedForecastMetric = _.isEmpty(selectedForecastMetric) ? "" :
  //   encodeURIComponent(
  //     JSON.stringify(_.get(state, 'forecasting.selectedForecastMetric'), {})
  //   );

  let currentForecastDateRange = _.get(state, 'forecasting.currentForecastDateRange');
  currentForecastDateRange = _.isEmpty(currentForecastDateRange) ? "" :
    encodeURIComponent(
      JSON.stringify(_.get(state, 'forecasting.currentForecastDateRange'), {})
    );
  let futureForecastDateRange = _.get(state, 'forecasting.futureForecastDateRange');
  futureForecastDateRange = _.isEmpty(futureForecastDateRange) ? "" :
    encodeURIComponent(
      JSON.stringify(_.get(state, 'forecasting.futureForecastDateRange'), {})
    );

  let forecastModelOptions = _.get(state, 'forecasting.forecastModelOptions');
  forecastModelOptions = _.isEmpty(forecastModelOptions) ? "" :
    encodeURIComponent(
      JSON.stringify(_.get(state, 'forecasting.forecastModelOptions'), [])
    );


  const locationHash = location.hash;
  let isRadarPage = false;
  let isForecastPage = false;
  let isMapCanvasPage = false;

  if(_.includes(locationHash, '!/forecasting')) {
    isForecastPage = true;
  }

  if(_.includes(locationHash, '!/forecasting-saved')) {
    isForecastPage = true;
  }

  if(_.includes(locationHash, '!/forecasting-metrics')) {
    isForecastPage = true;
  }

  if(_.includes(locationHash, '!/forecasting/concepts')) {
    isForecastPage = false;
  }

  if(_.includes(locationHash, '!/radar')) {
    isRadarPage = true;
  }

  if(_.includes(locationHash, '!/map-explorer')) {
    isMapCanvasPage = true;
  }

  let forecastStoreValue = {};

  if(isForecastPage){
    forecastStoreValue = {
      selectedForecastMetricField : _.get(selectedForecastMetric, 'field'),
      selectedForecastTemplateId,
      currentForecastDateRange,
      futureForecastDateRange,
      forecastModelOptions,
      forecastBookmarkId,
      axisGranularity,
      currentForecastPage
    }
  }

  let radarStoreValue = {};
  if(isRadarPage) {
    radarStoreValue = {
      radarBookmarkId,
      currentRadarDateRange,
      currentRadarDateType,
      currentRelativeDateFilterEntry,
      previousRadarDateRange,
      previousRadarDateType,
      previousRadarDateComparisonPeriod,
      selectedShapeAreaEntryId,
      selectedRadarTracts,
      comparisonTracts,
      selectAreaName,
      comparisonAreaName,
      isCheckedComparisonArea,
      isCheckedUnSelectedArea,
      selectedRadarMetricIds,
      selectedRadarMapMetricId,
      radarMetricFilters: newRadarMetricFilters,
      radarMetricGlobalFilters
    }
  }
  let mapCanvasStoreValue = {};
  if(isMapCanvasPage){
    mapCanvasStoreValue = getMapCanvasStateToUrl(state);
  }

  stateWithStoreValues = {
    ...stateWithStoreValues,
    visualization,
    dashboard,
    comparisonType,
    dashboardComparisonType,
    comparisonModeOn,
    comparisonDateRanges,
    dashboardComparisonDateRanges,
    currentDrilldownTemplateName,
    currentDrilldownTemplateId,
    currentDrilldownDimensionField,
    currentVisualizationType,
    currentDrilldownViewField,
    drilledDownDimensions,
    shapeIds,
    zoom,
    shapeDatasetEntryId,
    center,
    filteredGeojson,
    quickFilters: newQuickFilters,
    dateRange,
    dateType,
    geoCoder,
    sortColumns,
    isLeafPage,
    isTableView,
    currentMapView,
    currentMapStyle,
    outlines,
    leafPageCurrentRecordIndex,
    relativeDateFilterEntry,
    additionalDateFilters,
    collectionDateFilters,
    currentBookmarkId,
    currentBookmarkType,
    insightId,
    managePageCollectionId,
    subscriptionId,
    ...radarStoreValue,
    currentLeafData,
    isTimeOfDayOn,
    timeOfDayFilters,
    ...forecastStoreValue,
    ...mapCanvasStoreValue
  };

  stateWithStoreValues = _.pickBy(stateWithStoreValues, _.identity);

  stateWithStoreValues['currentDrilldownTemplateId'] = currentDrilldownTemplateId;
  if(isForecastPage){
    stateWithStoreValues['selectedForecastTemplateId'] = selectedForecastTemplateId;
    stateWithStoreValues['axisGranularity'] = axisGranularity;

  }
  const globalFilters= _.get(state, 'commonFilters.globalFilters', []);
  stateWithStoreValues['globalFilters'] = encodeURIComponent(JSON.stringify(globalFilters));
  stateWithStoreValues['currentDrilldownGroupByField'] = _.isEmpty(currentDrilldownGroupByField) ?
    '' : currentDrilldownGroupByField;

  return hashString + qs.stringify(stateWithStoreValues, {
    arrayFormat: 'repeat', addQueryPrefix: true, encode: false });
};

const getShapeIds = (shapeIds) => {
  return _.isEmpty(shapeIds) ? [] : formattedShapeId(shapeIds);
};

const formattedShapeId = (shapeIds) => {
  if(_.isEmpty(shapeIds)){
    return []
  }
  return _.map (shapeIds, (shapeId) => {
    if(!_.isNaN(Number(shapeId))){
      return Number(shapeId)
    } else {
      return shapeId
    }
  })
}

export const updateStoreFromUrl = (initialState, hashString, isAnalysisPage = false) => {
  const locationState = qs.parse(hashString, { comma: false , decoder: function (str) {
      if (str != 'false' && str != 'true') {
        str = decodeURIComponent(str);
      }
      return (str);
    }});
  let templateName;
  let templateId = _.get(locationState, 'currentDrilldownTemplateId') || null;

  const searchFilters = JSON.parse(_.get(locationState, 'filters', '[]'));

  if(_.isNull(templateId)) {
    templateName = _.get(locationState, 'currentDrilldownTemplateName');
    templateId = _.get(getCurrentTemplateEntryByName(templateName), 'template_id');
  } else {
    templateName = _.get(getCurrentTemplateEntryById(templateId), 'name');
  }
  const currentVisualizationType = _.get(locationState, 'currentVisualizationType');
  const groupByEntries =  getGroupByEntries(templateId);
  const viewEntries =  getViewEntries(templateId);
  const drilledDownDimensions = JSON.parse(_.get(locationState, 'drilledDownDimensions', '[]'));
  const dashboard = _.get(locationState, 'dashboard', '{}');
  const collectionDateFilters = _.get(locationState, 'collectionDateFilters', {});
  let dashboardReducer = {};
  dashboardReducer = JSON.parse(dashboard);
  const defaultVisualizationState = visualizationReducerDefaultState()[currentVisualizationType];
  const locationVisualizationState = JSON.parse(
    _.get(locationState, 'visualization', '{}')
  );
  let currentVisualizationReducerState = _.isEmpty(locationVisualizationState) ?
    defaultVisualizationState : locationVisualizationState;

  const newQuickFilters = JSON.parse(_.get(locationState, 'quickFilters', '[]'));
  // const isEmbed = _.get(initialState, 'embedOptions.isEmbed', false);
  const currentPeriodDateRangeFromState = getCurrentPeriodDateRangeFromState(locationState);
  const dateRange = _.isEmpty(currentPeriodDateRangeFromState) ?
    _.get(initialState, 'commonFilters.dateRange', {}) :
    currentPeriodDateRangeFromState;
  const relativeDateFilterEntry = _.get(locationState, 'relativeDateFilterEntry', {});
  const currentDrilldownViewField = _.get(locationState, 'currentDrilldownViewField');
  const viewEntry = getMatchedEntry(viewEntries, currentDrilldownViewField);
  const currentDrilldownGroupByField = _.get(locationState, 'currentDrilldownGroupByField', '');
  const currentDrilldownDimensionField = _.get(locationState, 'currentDrilldownDimensionField');
  const dimensionEntries = getDimensionEntries(templateId);
  const matchedDimensionEntry = getMatchedEntry(dimensionEntries, currentDrilldownDimensionField);
  const quickFilterEntries = getQuickFilterEntries(templateId, viewEntry, true);

  const templateEntry = getCurrentTemplateEntryById(templateId);
  const currentCardId = _.get(dashboardReducer, 'currentCardId', null)
  const collectionsWithCards = _.get(initialState, 'userCollections.userCollectionsWithCards', []);
  const currentCollectionId = _.get(dashboardReducer, 'currentCollectionId', null);
  const analysisPageCollectionId = _.get(dashboardReducer, 'analysisPageCollectionId', null);
  const cardId = getCurrentCardId(currentCardId, templateEntry, viewEntry);
  const collectionId = getCurrentCollectionId(collectionsWithCards, analysisPageCollectionId, cardId);

  const currentRadarDateRangeOptions = _.get(locationState, 'currentRadarDateRange', {});
  const currentRadarDateRange = _.isEmpty(currentRadarDateRangeOptions) ?
    _.get(initialState, 'radar.currentDateRangeOptions.dateRange', {}) :
    currentRadarDateRangeOptions;
  let relativeDateFilterEntryOptions = _.get(locationState, 'currentRelativeDateFilterEntry', '{}');
  relativeDateFilterEntryOptions = typeof relativeDateFilterEntryOptions == 'object' ?
    relativeDateFilterEntryOptions : JSON.parse(relativeDateFilterEntryOptions);
  const currentRelativeDateFilterEntry = _.isEmpty(relativeDateFilterEntryOptions) ?
    _.get(initialState, 'radar.currentDateRangeOptions.relativeDateFilterEntry', {}) :
    relativeDateFilterEntryOptions;

  const previousRadarDateRangeOptions = _.get(locationState, 'previousRadarDateRange', {})
  const previousRadarDateRange = _.isEmpty(previousRadarDateRangeOptions) ?
    _.get(initialState, 'radar.previousDateRangeOptions.dateRange', {}) :
    previousRadarDateRangeOptions;

  const previousRadarDateRangeType = _.get(locationState, 'previousRadarDateType', {})
  const previousRadarDateType = _.isEmpty(previousRadarDateRangeType) ?
    _.get(initialState, 'radar.previousDateRangeOptions.dateType', {}) :
    previousRadarDateRangeType;

  const previousRadarDateRangeComparisonPeriod = _.get(locationState, 'previousRadarDateComparisonPeriod', {})
  const previousRadarDateComparisonPeriod = _.isEmpty(previousRadarDateRangeComparisonPeriod) ?
    _.get(initialState, 'radar.previousDateRangeOptions.comparisonPeriod', {}) :
    previousRadarDateRangeComparisonPeriod;

  const selectedShapeAreaEntryId = _.get(locationState, 'selectedShapeAreaEntryId', {})
  const selectedShapeAreaEntry = _.isEmpty(selectedShapeAreaEntryId) ?
    getDefaultRadarAreaEntry() : getRadarAreaEntryById(selectedShapeAreaEntryId);

  const selectedRadarTracts = JSON.parse(_.get(locationState, 'selectedRadarTracts', '[]'));
  const comparisonTracts = JSON.parse(_.get(locationState, 'comparisonTracts', '[]'));
  const isCheckedUnSelectedArea = JSON.parse(_.get(locationState, 'isCheckedUnSelectedArea', 'true'));
  const isCheckedComparisonArea = JSON.parse(_.get(locationState, 'isCheckedComparisonArea', 'false'));

  const initialSelectAreaName = _.get(initialState, 'radar.selectAreaName', SHAPE_AREA_TABS[0].name);
  const initialComparisonAreaName = _.get(initialState, 'radar.comparisonAreaName', SHAPE_AREA_TABS[1].name);
  const selectAreaName = _.get(locationState, 'selectAreaName', initialSelectAreaName);
  const comparisonAreaName = _.get(locationState, 'comparisonAreaName', initialComparisonAreaName);

  const selectedRadarMetricIds = JSON.parse(_.get(locationState, 'selectedRadarMetricIds', '[]'));
  const selectedRadarMetricEntries =  _.compact(getSelectedRadarMetricEntriesByIds(selectedRadarMetricIds));

  const selectedRadarMapMetricId =  _.get(locationState, 'selectedRadarMapMetricId', '')
  const selectedMapMetric =  getSelectedRadarMapMetricById(selectedRadarMetricIds,selectedRadarMapMetricId);

  const radarMetricFilters = JSON.parse(_.get(locationState, 'radarMetricFilters', '[]'));
  const selectedRadarMetricFilters = _.isEmpty(radarMetricFilters) ?
    _.compact(getDefaultRadarMetricFilters(selectedRadarMetricEntries)) : radarMetricFilters;

  const radarMetricGlobalFilters = JSON.parse(_.get(locationState, 'radarMetricGlobalFilters', '[]'));
  const radarBookmarkId =_.get(locationState, 'radarBookmarkId', '[]');

  // forecast

  // let selectedForecastMetricOptions = _.get(locationState, 'selectedForecastMetric', '{}');
  // selectedForecastMetricOptions =  typeof selectedForecastMetricOptions == 'object' ?
  // selectedForecastMetricOptions : JSON.parse(selectedForecastMetricOptions);

  const selectedForecastTemplateId = _.get(locationState, 'selectedForecastTemplateId');
  const axisGranularity = _.get(locationState, 'axisGranularity');
  const forecastBookmarkId =_.get(locationState, 'forecastBookmarkId', '');
  const currentForecastPage =_.get(locationState, 'currentForecastPage', '');
  const selectedForecastMetricField =_.get(locationState, 'selectedForecastMetricField', '');
  const forecastViewEntries =  getViewEntries(selectedForecastTemplateId);
  const selectedForecastMetric = getMatchedEntry(forecastViewEntries, selectedForecastMetricField);
  let currentForecastDateRangeOptions = _.get(locationState, 'currentForecastDateRange', '{}');
  currentForecastDateRangeOptions =  typeof currentForecastDateRangeOptions == 'object' ?
    currentForecastDateRangeOptions : JSON.parse(currentForecastDateRangeOptions);

  const currentForecastDateRange = _.isEmpty(currentForecastDateRangeOptions) ?
    _.get(initialState, 'forecasting.currentForecastDateRange', {}) :
    currentForecastDateRangeOptions;

  let futureForecastDateRangeOptions = _.get(locationState, 'futureForecastDateRange', '{}');
  futureForecastDateRangeOptions =  typeof futureForecastDateRangeOptions == 'object' ?
    futureForecastDateRangeOptions : JSON.parse(futureForecastDateRangeOptions);

  const futureForecastDateRange = _.isEmpty(futureForecastDateRangeOptions) ?
    _.get(initialState, 'forecasting.futureForecastDateRange', {}) :
    futureForecastDateRangeOptions;

  const forecastModelOptions =JSON.parse(_.get(locationState, 'forecasting.forecastModelOptions', '[]'));
  let comparisonDateRanges = _.get(locationState, 'comparisonDateRanges', '[]');
  comparisonDateRanges = typeof comparisonDateRanges === 'string'
  ? JSON.parse(_.get(locationState, 'comparisonDateRanges', '[]'))
  : _.get(locationState, 'comparisonDateRanges', []);

  let dashboardComparisonDateRanges = _.get(locationState,
    'dashboardComparisonDateRanges', '[]');
  dashboardComparisonDateRanges = typeof dashboardComparisonDateRanges === 'string'
  ? JSON.parse(_.get(locationState, 'dashboardComparisonDateRanges', '[]'))
  : _.get(locationState, 'dashboardComparisonDateRanges', []);

  const comparisonType = _.get(locationState, 'comparisonType');
  const dashboardComparisonType = _.get(locationState, 'dashboardComparisonType', comparisonType);

  const comparisonModeOn = JSON.parse(_.get(locationState, 'comparisonModeOn', false));
  if(comparisonModeOn && comparisonType == 'same_period_prior_year') {
    const dateType = _.get(locationState, 'dateType');
    const yearOptions = getCompareSamePeriodLastYearOptions(
      { primaryDateRange: dateRange, primaryDateType: dateType, templateId });
    const selectYears = getSelectedYearsForBienniumYear(yearOptions, comparisonDateRanges, dateType);

    if(_.isEmpty(selectYears)){
      comparisonDateRanges = [_.get(yearOptions, '[0].range')];
    }
  }
  if(comparisonModeOn && dashboardComparisonType == 'same_period_prior_year') {
    const yearOptions = getCompareSamePeriodLastYearOptions(
      { primaryDateRange: dateRange, primaryDateType: _.get(locationState, 'dateType'), templateId });
    const selectDashboardYears = _.filter(yearOptions, (yearEntry) => {
      return _.some(dashboardComparisonDateRanges, yearEntry['range']);
    });
    if(_.isEmpty(selectDashboardYears)){
      dashboardComparisonDateRanges = [_.get(yearOptions, '[0].range')];
    }
  }

  initialState = {
    ...initialState,
    dashboard: {
      ...dashboardReducer,
      currentCardId: cardId,
      currentCollectionId: currentCollectionId,
      analysisPageCollectionId: collectionId,
      manageCollection: {},
    },
    visualization: {
      ...visualizationReducerDefaultState(),
      [currentVisualizationType]: {
        ...currentVisualizationReducerState
      },
      mapOptions:{
        ...mapOptionsReducerDefaultState(),
        currentMapStyleEntry: getSelectedMapStyleEntry(
          templateId, _.get(locationState, 'currentMapStyle', '')),
        currentMapView: getSelectedMapViewEntry(_.get(locationState, 'currentMapView', '')),
        geoCoder: _.get(locationState, 'geoCoder', {}),
        selectedOutlines: getSelectedOutlineEntries(templateId, _.get(locationState, 'outlines')),
        shape:{
          datasetEntry: getDefaultShapeDatasetEntry(templateId, _.get(locationState, 'shapeDatasetEntryId')),
          selectedShapeIds: getShapeIds(JSON.parse(_.get(locationState, 'shapeIds', '[]'))),
        },
        filteredGeojson: JSON.parse(_.get(locationState, 'filteredGeojson', '{}')),
        centerAndZoom: {
          center : _.get(locationState, 'center'),
          zoom : _.get(locationState, 'zoom')
        }
      },
      table: {
        showTableViewOnDrilldown: false,
        sortColumns:  JSON.parse(_.get(locationState, 'sortColumns', '[]')),
        isLeafPage: JSON.parse(_.get(locationState, 'isLeafPage', false)),
        isTableView: JSON.parse(_.get(locationState, 'isTableView', false)),
        leafPageCurrentRecordIndex: Number(_.get(locationState, 'leafPageCurrentRecordIndex', 0)),
        currentLeafData: JSON.parse(_.get(locationState, 'currentLeafData', '[]')),
      }
    },
    drilldown: {
      currentDrilldownTemplateName: templateName,
      currentDrilldownTemplateId: templateId,
      currentVisualizationType: currentVisualizationType,
      currentDrilldownDimensionField: _.get(matchedDimensionEntry, 'field', currentDrilldownDimensionField),
      currentDrilldownGroupByEntry: getMatchedEntry(groupByEntries, currentDrilldownGroupByField),
      currentDrilldownViewEntry: viewEntry,
      drilledDownDimensions:  addFieldForFilters(drilledDownDimensions, dimensionEntries),
      selectedOutlines: [],
      permissionFilters: [],
      quickFilters: addFieldForFilters(newQuickFilters, quickFilterEntries),
      isTimeOfDayOn: JSON.parse(_.get(locationState, 'isTimeOfDayOn', 'false')),
      timeOfDayFilters: JSON.parse(_.get(locationState, 'timeOfDayFilters', '[]')),
    },
    commonFilters: {
      ..._.get(initialState, 'commonFilters', {}),
      dateRange,
      comparisonType,
      dashboardComparisonType,
      comparisonDateRanges,
      dashboardComparisonDateRanges,
      comparisonModeOn,
      dateType: _.get(locationState, 'dateType'),
      relativeDateFilterEntry: _.isEmpty(relativeDateFilterEntry) ?
        relativeDateFilterEntryDefaultState :
        relativeDateFilterEntry,
      additionalDateFilters: _.get(locationState, 'additionalDateFilters', {}),
      globalFilters: (isAnalysisPage ? _.get(locationState, 'globalFilters', []) :
        _.get(initialState, 'commonFilters.globalFilters', [])),
      collectionDateFilters
    },
    bookmark: {
      currentBookmarkId:  _.get(locationState, 'currentBookmarkId'),
      currentBookmarkType: _.get(locationState, 'bookmark.currentBookmarkType', ''),
    },
    insight : {
      insightId : _.get(locationState, 'insightId', '')
    },
    manage:{
      collectionId: _.get(locationState, 'managePageCollectionId', ''),
      subscriptionId: _.get(locationState, 'subscriptionId', '')
    },
    radar:{
      currentDateRangeOptions: {
        dateRange: currentRadarDateRange,
        dateType: _.get(locationState, 'currentRadarDateType','custom_range'),
        relativeDateFilterEntry: currentRelativeDateFilterEntry
      },
      previousDateRangeOptions: {
        dateRange: previousRadarDateRange,
        dateType: previousRadarDateType,
        comparisonPeriod: previousRadarDateComparisonPeriod
      },
      selectedShapeAreaEntry: selectedShapeAreaEntry,
      selectedTracts: selectedRadarTracts,
      comparisonTracts: comparisonTracts,
      selectedMetricEntries: selectedRadarMetricEntries,
      selectedMapMetric: selectedMapMetric,
      metricFilters:selectedRadarMetricFilters,
      metricGlobalFilters:radarMetricGlobalFilters,
      isCheckedUnSelectedArea,
      isCheckedComparisonArea,
      selectAreaName,
      comparisonAreaName,
      bookmarkId:radarBookmarkId
    },
    forecasting:{
      selectedForecastTemplateId,
      currentForecastDateRange,
      futureForecastDateRange,
      forecastModelOptions,
      bookmarkId: forecastBookmarkId,
      selectedForecastMetric: _.isEmpty(forecastBookmarkId) ? selectedForecastMetric : {},
      axisGranularity,
      currentForecastPage: currentForecastPage
    },
    advanceSearch: {
      searchField: JSON.parse(_.get(locationState, 'searchField', '{}')),
      filters: searchFilters,
      templateId: _.get(locationState, 'templateId'),
      subjectRowId: _.get(locationState, 'subjectRowId'),
      searchPage: _.get(locationState, 'searchPage'),
      selectedShapeIds: JSON.parse(_.get(locationState, 'selectedShapeIds', '{}')),
      shapeDatasetEntry: JSON.parse(_.get(locationState, 'shapeDatasetEntry', '{}')),
      selectedReportRowIds: _.compact(JSON.parse(_.get(locationState, 'selectedReportRowIds', '[]'))),
      sortColumns:  JSON.parse(_.get(locationState, 'sortColumns', '[]')),
      searchProperty: JSON.parse(_.get(locationState, 'searchProperty', '{}')),
      isCompareListOpen:  _.get(locationState, 'isComparisonListOpen', false),
      polygonsGeojson: JSON.parse(_.get(locationState, 'filteredGeojson', '{}'))
    },
    mapCanvas: getMapCanvasStateFromUrl(initialState, locationState)
  };

  const globalFilters = JSON.parse(_.get(locationState, 'globalFilters', '[]'));
  initialState['commonFilters']['globalFilters'] = globalFilters;

  return initialState;
};

const _getCurrentVisualizationConfigs = (state, currentVisualizationType) => {
  const defaultState = visualizationReducerDefaultState();
  const currentConfigs = _.get(state, ['visualization', currentVisualizationType], {});
  if(_.isEmpty(currentConfigs)) {
    return _.get(defaultState, currentVisualizationType, {});
  } else {
    return currentConfigs;
  }
}

const getMatchedEntry = (entries, selectedField) => {
  return _.find(entries, (entry) => {
    const { field, field_with_dataset_id } = entry;
    const fields = [field, field_with_dataset_id];
    // if(useFieldIndex) {
    //   fields.push(matched_entry_field_with_index);
    // }
    return _.includes(fields, selectedField);
  }) || {};
}

const addFieldForFilters = (filters, filterEntries) => {
  return _.map(filters, (filter) => {
    const { field } = filter;
    const matchedEntry = getMatchedEntry(filterEntries, field);

    return { ...filter, field: _.get(matchedEntry, 'field', field) };
  });
}

const isCardInCollection = (collectionsWithCards, collectionId, cardId) => {
  const currentCollection = _.find(collectionsWithCards, { 'id': collectionId });
  if (currentCollection) {
    const collectionCards = _.get(currentCollection, 'cards', []);
    return _.some(collectionCards, { 'card_id': cardId });
  }
  return false;
};

const getCurrentCardId = (currentCardId, templateEntry, viewEntry) => {
  if (_.isNull(currentCardId)) {
    return getCardId(templateEntry, viewEntry)
  }
  return currentCardId;
};

const getCurrentCollectionId = (collectionsWithCards, currentCollectionId, cardId) => {
  const isCollectionIdNull = _.isNull(currentCollectionId) ||
    _.isEqual(currentCollectionId, COLLECTION_ID.ALL_METRICS) ||
    (!_.isNull(currentCollectionId) &&
      !isCardInCollection(collectionsWithCards, currentCollectionId, cardId))

  if (isCollectionIdNull) {
    return getCollectionId(collectionsWithCards, cardId)
  }
  return currentCollectionId;
};
