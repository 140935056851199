import React from "react"
import PropTypes from 'prop-types'
import { VISUALIZATION_TYPES } from "appConstants";
import {
  getDefaultVisualizationView,
  showAnnotationValueInBar } from "common/config/visualizationConfiguration";
import { getDrilldownEntryParams } from "pages/dashboard/components/TemplateCards/TemplateCard";
import {
  getAvailableVisualizationType,
  getDefaultVisualizationChartView
} from "helpers/visualizationHelper";
import { getCardId } from "pages/embed/helper";
import { getMapViews } from "modules/Map/helpers/MapOptionsHelper";
import TemplateCardVisualization from "pages/dashboard/components/TemplateCards/TemplateCardVisualization";
import { useSelector } from "react-redux";

const MetricVisualization = ({metricCardEntry}) => {
  const commonFilters = useSelector(state => _.get(state, 'commonFilters', {}));
  const { viewEntry, userCardEntry, templateEntry } = metricCardEntry;
  const isBookmarkCard = _.get(userCardEntry, 'isBookmarkCard', false);
  const visualization = _.get(userCardEntry, 'visualization', {});

  const getVisualizationType = () => {
    if(isBookmarkCard){
      return  _.get(userCardEntry, 'drilldown.currentVisualizationType',
        VISUALIZATION_TYPES.SNAPSHOT.type
      );
    }
    const defaultView = getDefaultVisualizationView(viewEntry);
    const drilldownEntry = getDrilldownEntryParams(userCardEntry, viewEntry);
    const { currentDrilldownViewEntry } = drilldownEntry;
    const templateId = _.get(userCardEntry, 'template_id');
    if (defaultView == 'none') {
      return defaultView;
    }
    return getAvailableVisualizationType(templateId, defaultView, currentDrilldownViewEntry);
  }

  const showLegendTotalLine = false;
  // const { currentDimensionTotal, isChartContentLoading, isTotalLoading} = this.state;

  const defaultView = getDefaultVisualizationView(viewEntry);
  if (defaultView == 'none') {
    return;
  }
  // const defaultChartView = getDefaultVisualizationChartView(
  //   viewEntry,
  //   defaultView
  // );
  // const isPieChartView = (_.isEqual(defaultChartView, SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type));
  const drilldownEntry = getDrilldownEntryParams(templateEntry, viewEntry);
  const defaultMapView = getDefaultVisualizationChartView(
    viewEntry,
    VISUALIZATION_TYPES.MAP.type
  );
  const mapOptions = {
    currentMapView: _.get(getMapViews(), defaultMapView),
  };
  const showChartValues = showAnnotationValueInBar(viewEntry);
  const cardId = `chart-${getCardId(templateEntry, viewEntry)}`;

  const styles = {
    width : '300px',
    height : '230px'
  }
  return (
    <div id={cardId} style={styles}
      className="metric-card-visualization js-plotly-plot mt-2">
      <TemplateCardVisualization
        isChartAndTotalLoading={false}
        onDataLoading={_.noop}
        commonFilters={commonFilters}
        cardEntry={templateEntry}
        mapOptions={mapOptions}
        visualizationType={getVisualizationType()}
        drilldownEntry={drilldownEntry}
        isEmbed={false}
        isBookmarkCard={isBookmarkCard}
        viewEntry={viewEntry}
        showChartValues={showChartValues}
        cardImageId={cardId}
        showLegendTotalLine={showLegendTotalLine}
        visualizationEntry={visualization}
        isHoverOverDisabled={true}
      />
    </div>
  )
};

MetricVisualization.propTypes = {
  metricCardEntry: PropTypes.object.isRequired
}
export default MetricVisualization;
