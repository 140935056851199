import React from 'react'
import PropTypes from 'prop-types'
import {
  ForgeIcon,
  ForgeList,
  ForgeListItem,
  ForgeMiniDrawer,
  ForgeScaffold,
  ForgeTooltip
} from '@tylertech/forge-react'
import { LIBRARY_TYPE } from 'appConstants';
import MetricLibraryCards from './MetricLibraryCards';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

function MetricLibraryTabs({
  metricLibraryOption,
  libraryType,
  onSelectLibraryType,
  metricCardEntries,
  placeCardIntoCollection,
  onSavedViewDelete
}) {
  const renderCards = () => {
    return <MetricLibraryCards
      metricLibraryOption={metricLibraryOption}
      metricCardEntries={metricCardEntries}
      libraryType={libraryType}
      placeCardIntoCollection={placeCardIntoCollection}
      onSavedViewDelete={onSavedViewDelete}/>;
  }

  const onEnterKeyDown = (e, type) => {
    const libraryType = type === 'all_metrics' ?
      LIBRARY_TYPE.ALL_METRICS : LIBRARY_TYPE.MY_SAVED_VIEWS;
    if(isEnterButtonPressed(e)){
      onSelectLibraryType(libraryType);
    }
  }
  return (
    <ForgeScaffold className='metric-library-scaffold' tabIndex={0}>
      <ForgeMiniDrawer slot="left" direction="left" hover={false}>
        <ForgeList>
          <ForgeListItem id="tooltip-host-1" tabIndex={0}
            active={libraryType === LIBRARY_TYPE.ALL_METRICS}
            onclick={() => onSelectLibraryType(LIBRARY_TYPE.ALL_METRICS)}
            onkeydown={(e) => onEnterKeyDown(e, 'all_metrics')}
            >
            <ForgeIcon slot="leading" name="file_document_multiple" />
            All metrics
          </ForgeListItem>
          <ForgeListItem id="tooltip-host-2" tabIndex={0}
            active={libraryType === LIBRARY_TYPE.MY_SAVED_VIEWS}
            onclick={() => onSelectLibraryType(LIBRARY_TYPE.MY_SAVED_VIEWS)}
            onkeydown={(e) => onEnterKeyDown(e, 'saved_views')}>
            <ForgeIcon slot="leading" name="book_outline" />
            My saved views
          </ForgeListItem>
          <ForgeTooltip target="#tooltip-host-1" position="top">All metrics</ForgeTooltip>
          <ForgeTooltip target="#tooltip-host-2" position="top">My saved views</ForgeTooltip>
        </ForgeList>
      </ForgeMiniDrawer>
      <div slot="body" className='metric-library-scaffold-cards'>
        {renderCards()}
      </div>
    </ForgeScaffold>
  )
}

MetricLibraryTabs.propTypes = {
  metricLibraryOption: PropTypes.object,
  libraryType: PropTypes.string,
  onSelectLibraryType: PropTypes.func,
  metricCardEntries: PropTypes.array,
  placeCardIntoCollection: PropTypes.func,
  onSavedViewDelete: PropTypes.func
}

export default MetricLibraryTabs
