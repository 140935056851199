import { deleteCard } from "common/api/collectionApi";
import { useState } from "react";

export const useDeleteMetricCardFromCollection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const deleteCardData = async (collectionId, cardId) => {
    setIsLoading(true);
    try {
      const response = await deleteCard(collectionId, cardId);
      if (!response.ok) {
        throw new Error("Failed to delete card");
      }
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }

  return { deleteCardData, isLoading, error, success };
};
