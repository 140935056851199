import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';

import DeleteConfirmModal from '../DeleteConfirmModal';
import LoadingSpinner from 'common/components/LoadingSpinner';
import WatchCollection from './Subscription/WatchCollection';
import ShareCollection from './ShareCollection';
import InsightsInfo from './InsightsInfo';
import CollaborateModal from '../ManageCollection/CollaborateModal';
import Store from '../ManageCollection/Store';

import { showInsight } from 'common/config/customerConfiguration';
import { isMyViews } from 'pages/dashboard/components/CardList/cardHelper';
import {
  collectionDeleteMessage,
  collectionDeleteErrorMessage,
  collectionCopiedMessage,
  collectionCopiedErrorMessage
} from 'helpers/toastMessages';
import { viewEntriesUserRoles } from 'pages/dashboard/components/Collections/collectionHelper';
import { copyCollection, deleteCollection } from 'common/api/collectionApi';
import { COLLECTION_ID, COLLECTION_TYPES } from 'appConstants';
import {
  canShowDeleteButton,
  canShowCopyButton,
  isCollaborateCollection,
  isManageCollections,
  isUserCollection,
  isRestrictedCollection
} from '../ManageCollection/collaboratorHelper';
import { collectionTopMovers, collectionSLAWatch } from './collectionHelper';
import UserFromBellerophonContext from "context/UserFromBellerophonContext";
import CopyCollection from './CopyCollection';
import MetricLibrary from './MetricLibrary/MetricLibrary';

class CollectionActionIcons extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
      isShareModalOpened: false,
      isCollaborateModel: false,
      autoConvertCollaborate: false
    };
  }
  static contextType = UserFromBellerophonContext;

  handleCollectionDelete = () => {
    const {
      currentUser, onFetchCollections, currentCollection, dispatchSetCurrentCollectionId
    } = this.props;
    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      deleteCollection(_.get(currentCollection, 'id'))
        .then((response) => response.json())
        .then((response) => {
          if (response.ok || response.message) {
            onFetchCollections({}, COLLECTION_ID.ALL_METRICS);
            this.setState({ isLoading: false });
            toast.success(collectionDeleteMessage);
            dispatchSetCurrentCollectionId(COLLECTION_ID.ALL_METRICS);
          } else {
            this.setState({ isLoading: false });
            toast.error(collectionDeleteErrorMessage);
          }
        });
    }
  };

  handleCopyCollectionSave = (copyCollectionName, isMoveCollaborate = false) => {
    const { currentUser, currentCollection, onFetchCollections } = this.props;
    if (!_.isEmpty(copyCollectionName) && !_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      let collection = {
        name: _.trim(copyCollectionName),
        sort_order: 0,
        is_hidden: _.get(currentCollection, 'is_hidden', false),
        dateFilters: _.get(currentCollection, 'dateFilters', {}),
        collectionFilters: _.get(currentCollection, 'collectionFilters', []),
        copied_collection_id: _.get(currentCollection, 'id')
      };

      if (_.get(currentCollection, 'is_collaborate_collection', false)){
        collection = {
          ...collection,
          is_collaborate_collection: true,
          manage_collaborate_updated_at: new Date().toISOString()
        }
      }

      copyCollection(collection).then((response) => {
          if (!response.ok) {
            this.setState({ isLoading: false });
            toast.error(collectionCopiedErrorMessage);
            return null;
          }
          return response.json();
        }).then((response) => {
            this.setState({
              isLoading: false,
              isCollaborateModel: isMoveCollaborate,
              autoConvertCollaborate: isMoveCollaborate });
          if (!_.isEmpty(response)) {
            toast.success(collectionCopiedMessage);
            let updateOption = {
              toBeUpdateCollection: response,
              isUpdateSortOrder: true
            }

            if(isMoveCollaborate){
              updateOption['isMoveCollaborate'] = true;
            }
            onFetchCollections(updateOption);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false, isCollaborateModel: false });
          toast.error(`Error while copy collection. ${err}`);
        });
    }
  }

  onShareButtonClick = (isOpen) => {
    this.setState({ isShareModalOpened: isOpen });
  }

  shouldShowInsightsInfo = () => {
    const { cardEntries } = this.props;
    const topMovers = collectionTopMovers(cardEntries);
    const slaWatch = collectionSLAWatch(cardEntries);
    const shouldShowInsight = showInsight();

    return shouldShowInsight && (!_.isEmpty(topMovers) || !_.isEmpty(slaWatch));
  }

  hideActionIcons = () => {
    const { currentCollection, areCollectionsLoaded, currentUser } = this.props;
    const isUserCollections = _.get(currentCollection, 'type') == COLLECTION_TYPES.USER

    const isInsightInfoEnabled = !this.shouldShowInsightsInfo() || areCollectionsLoaded;
    const showCollectionAction = _.isEmpty(currentUser) || !isUserCollections;

    return ( _.isEmpty(currentCollection) || isMyViews(currentCollection)) ||
      (isInsightInfoEnabled && showCollectionAction);
  }

  renderCollectionShare() {
    const { currentCollection, currentUser, cardEntries, updateOverwriteCollectionFilter } = this.props;
    const isLaunchpadAdmin = _.get(this.context, "userFromBellerophon.isLaunchpadAdmin", false);
    let allowedUserRoles = []
    if (!isLaunchpadAdmin){
      allowedUserRoles = viewEntriesUserRoles(cardEntries);
    }
    return (
      <ShareCollection
        currentUser={currentUser}
        currentCollection={currentCollection}
        onShareClick={this.onShareButtonClick}
        allowedUserRoles={allowedUserRoles}
        updateOverwriteCollectionFilter={updateOverwriteCollectionFilter}
      />
    );
  }

  renderCopyCollection(isMoveCollaborate = false) {
    const { currentCollection } = this.props;

    return (
      <CopyCollection
        currentCollection={currentCollection}
        onCopyCollectionSave = {this.handleCopyCollectionSave}
        isMoveCollaborate = {isMoveCollaborate}
      />
    );
  }

  renderCollectionWatch() {
    const {
      currentCollection, currentUser, cardEntries, onCollaborateCollectionChange, templateEntries
    } = this.props;
    const { isShareModalOpened } = this.state;
    if (isShareModalOpened) {
      return null;
    }
    const isLaunchpadAdmin = _.get(this.context, "userFromBellerophon.isLaunchpadAdmin", false);
    let allowedUserRoles = []
    if (!isLaunchpadAdmin){
      allowedUserRoles = viewEntriesUserRoles(cardEntries);
    }

    return (
      <WatchCollection
        currentUser={currentUser}
        onCollaborateCollectionChange={onCollaborateCollectionChange}
        currentCollection={currentCollection}
        allowedUserRoles={allowedUserRoles}
        templateEntries={templateEntries}
        cardEntries={cardEntries}
      />
    );
  }

  renderCollectionDelete() {
    const { currentCollection } = this.props;
    const { isShareModalOpened } = this.state;
    if (!_.isEmpty(_.get(currentCollection, 'bellerophon_tag_id')) || isShareModalOpened) {
      return null;
    }
    return (
      <>
        <DeleteConfirmModal
          showDeleteIcon={false}
          title="Delete collection?"
          aria-label="Delete collection?"
          message="Would you like to delete your collection? There is no undo."
          onDeleteConfirm={this.handleCollectionDelete}
        />
      </>
    );
  }

  renderInsightsInfo() {
    const { currentCollection, areCollectionsLoaded, cardEntries } = this.props;
    const currentCollectionId = _.get(currentCollection, 'id', null);
    const topMovers = collectionTopMovers(cardEntries);
    const slaWatch = collectionSLAWatch(cardEntries);

    if (!this.shouldShowInsightsInfo() || isMyViews(currentCollection)) {
      return null;
    }

    return (
      <InsightsInfo
        topMovers={topMovers}
        slaWatch={slaWatch}
        currentCollectionId={currentCollectionId}
        areCollectionsLoaded={areCollectionsLoaded} />
    );
  }

  handleUpdateToggleModel = (status) => {
    this.setState({
      isCollaborateModel : status,
      autoConvertCollaborate: false
    })
  }

  renderCollaborateContent = () => {
    const { currentCollection, cardEntries, currentUser, onCollaborateCollectionChange } = this.props;
    const { isCollaborateModel, autoConvertCollaborate } = this.state;
    const isLaunchpadAdmin = _.get(this.context, "userFromBellerophon.isLaunchpadAdmin", false);
    let allowedUserRoles = []
    if (!isLaunchpadAdmin){
      allowedUserRoles = viewEntriesUserRoles(cardEntries);
    }

    if(isUserCollection(this.props.currentCollection)){
      return (
        <Provider store={
            Store({ currentCollection, allowedUserRoles, currentUser, isOpenModel: isCollaborateModel })}>
          <CollaborateModal
            onCollaborateCollectionChange={onCollaborateCollectionChange}
            isLaunchpadAdmin={isLaunchpadAdmin}
            autoConvertCollaborate = {autoConvertCollaborate}
            onUpdateToggleModel={this.handleUpdateToggleModel}/>
        </Provider>
      );
    } else if(isLaunchpadAdmin || isManageCollections()) {
      return this.renderCopyCollection(true);
    }
  }

  renderCollectionActions() {
    const { currentCollection, currentUser } = this.props;
    const isAllMetrics = _.isEqual(currentCollection.id, COLLECTION_ID.ALL_METRICS);

    if (_.isEmpty(currentUser) || isMyViews(currentCollection) || isAllMetrics ) {
      return null;
    }

    return (
      <>
        {this.renderCollectionWatch()}
        {!isCollaborateCollection(currentCollection) && this.renderCollectionShare()}
        {canShowCopyButton(currentCollection) && this.renderCopyCollection()}
        {this.renderCollaborateContent()}
        {canShowDeleteButton(currentCollection) && this.renderCollectionDelete()}
      </>
    );
  }

  renderMetricLibrary = () => {
    const {
      currentCollection,
      currentUser,
      isManageMetricLibrary,
      onHandleMetricLibrary
    } = this.props;

    const isAllMetrics = _.isEqual(currentCollection.id, COLLECTION_ID.ALL_METRICS);

    if (_.isEmpty(currentUser) ||
        isRestrictedCollection(currentCollection) ||
        isMyViews(currentCollection) || isAllMetrics ) {
      return null;
    }

    return(
      <MetricLibrary
        isManageMetricLibrary={isManageMetricLibrary}
        onHandleMetricLibrary={onHandleMetricLibrary} />
    )
  }

  render() {
    const { isLoading } = this.state;

    if (this.hideActionIcons()) {
      return null;
    }

    return (
      <div className="ml-auto order-md-2 order-1">
        <LoadingSpinner isLoading={isLoading} />
        <div className="collection-action">
          <div className="collection-action-btn">
            {this.renderInsightsInfo()}
            {this.renderCollectionActions()}
            {this.renderMetricLibrary()}
          </div>
        </div>
      </div>
    );
  }
}

CollectionActionIcons.propTypes = {
  currentCollection: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentUser: PropTypes.object,
  cardEntries: PropTypes.array,
  dateFilters: PropTypes.object,
  onFetchCollections: PropTypes.func,
  onUpdateCollection: PropTypes.func,
  areCollectionsLoaded: PropTypes.bool,
  updateOverwriteCollectionFilter: PropTypes.func,
  onCollectionChange: PropTypes.func,
  onCollaborateCollectionChange: PropTypes.func,
  isManageMetricLibrary: PropTypes.bool,
  onHandleMetricLibrary: PropTypes.func,
  templateEntries: PropTypes.array,
  dispatchSetCurrentCollectionId: PropTypes.func
};

export default CollectionActionIcons;
