import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ForgeBadge,
  ForgeDivider,
  ForgeDrawer,
  ForgeIcon,
  ForgePopup
} from '@tylertech/forge-react';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { PopupAnimationType } from '@tylertech/forge';
import MetricSearch from './MetricSearch';
import { getBookmarkMetricEntries } from 'helpers/templateHelper';
import MetricLibraryTabs from './MetricLibraryTabs';
import ManageMetricLibrary from './ManageMetricLibrary';
import LibraryTitle from './LibraryTitle';
import { LIBRARY_TYPE, LIST_OF_LIBRARIES, METRIC_LIBRARY_ORDER } from 'appConstants';
import { domainWiseMetricLogs } from 'common/api/metricTotal';
import { getOrderedBookmarks, getOrderedAllMetricEntries } from './metricLibraryHelper';
function MetricDrawer(props) {
  const { metricLibraryOption, placeCardIntoCollection } = props;
  const {
    isManageMetricLibrary,
    onHandleMetricLibrary,
    bookmarkEntries,
    templateEntries
  } = metricLibraryOption;
  const [allMetricsLogs, setAllMetricsLogs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLibraryType, setSelectedLibraryType] = useState('');
  const [metricCardEntries, setMetricCardEntries] = useState([]);
  const [metricLibraryOrder, setMetricLibraryOrder] = useState(METRIC_LIBRARY_ORDER.alphabetically);
  const targetInfoRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    domainWiseMetricLogs()
      .then((response) => {
        setIsLoading(false);
        setAllMetricsLogs(response);
    });
  },[])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  },[])

  useEffect(() => {
    if(_.isEmpty(selectedLibraryType)) {
      setMetricCardEntries([]);
    } else {
      const options = {
        templateEntries,
        allMetricsLogs,
        metricLibraryOrder,
        searchMetricName: ''
      }
      const metricEntries =  selectedLibraryType === LIBRARY_TYPE.ALL_METRICS ?
      getOrderedAllMetricEntries(options) : getOrderedBookmarks(metricLibraryOrder, bookmarkEntries);

      setMetricCardEntries(metricEntries);
    }
  }, [selectedLibraryType])

  useEffect(() => {
    const options = {
      templateEntries,
      allMetricsLogs,
      metricLibraryOrder,
      searchMetricName: ''
    }
    const metricEntries =  selectedLibraryType === LIBRARY_TYPE.ALL_METRICS ?
      getOrderedAllMetricEntries(options) : getOrderedBookmarks(metricLibraryOrder, bookmarkEntries);
    setMetricCardEntries(metricEntries);
  }, [metricLibraryOrder])

  const handleSelectedLibraryType = (libraryType) => {
    if (libraryType === LIBRARY_TYPE.ALL_METRICS) {
      setMetricLibraryOrder(METRIC_LIBRARY_ORDER.alphabetically);
    } else {
      setMetricLibraryOrder(METRIC_LIBRARY_ORDER.recentlyOpened);
    }
    setSelectedLibraryType(libraryType);
  }

  const handleClickOutside = (e) => {
    if (targetInfoRef && targetInfoRef.current
      && !targetInfoRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }

  const handleSearchMetric = (searchMetricName) => {
    let metricCards =[];
    if(selectedLibraryType === LIBRARY_TYPE.ALL_METRICS) {
      const options = {
        templateEntries,
        allMetricsLogs,
        metricLibraryOrder,
        searchMetricName: searchMetricName
      }
      metricCards = getOrderedAllMetricEntries(options);
    } else if(selectedLibraryType === LIBRARY_TYPE.MY_SAVED_VIEWS) {
      metricCards = getBookmarkMetricEntries(bookmarkEntries, searchMetricName);
    }

    setMetricCardEntries(metricCards);
  }

  const onSavedViewDelete = (viewId) => {
    const cardEntriesAfterDelete = _.reject(metricCardEntries, {id: viewId});
    setMetricCardEntries(cardEntriesAfterDelete);
  }
  const renderMetricInfo = () => {
    const defaultOption = {
      placement: 'bottom',
      animationType: PopupAnimationType.Dropdown,
      offset: { x: 0, y: 0 }
    }
    return (
      <div className='mt-1 cursor-pointer'>
        <ForgeIcon name="info" ref={targetInfoRef} onClick={() => setIsOpen(!isOpen)}></ForgeIcon>
        <ForgePopup
          targetElementRef={targetInfoRef}
          open={isOpen}
          onDismiss={() => setIsOpen(false)}
          options={defaultOption}>
          <div style={{ width: '256px', padding: '15px' }} className="forge-typography--body1">
            To add a card to your collection,
            simply drag it from the library and drop it into the collection workspace.
          </div>
        </ForgePopup>
      </div>
    );
  }

  const renderManageLibrary = () => {
    if(_.isEmpty(selectedLibraryType)) {
      return <ManageMetricLibrary onSelectLibraryType={handleSelectedLibraryType} />;
    }

    return <MetricLibraryTabs
      metricLibraryOption={metricLibraryOption}
      libraryType={selectedLibraryType}
      metricCardEntries={metricCardEntries}
      onSelectLibraryType={handleSelectedLibraryType}
      placeCardIntoCollection={placeCardIntoCollection}
      onSavedViewDelete={onSavedViewDelete}/>
  }

  const renderSearchMetric = () => {
    if(_.isEmpty(selectedLibraryType)){
      return null;
    }

    return (
      <MetricSearch selectedLibraryType={selectedLibraryType}
        onHandleSearchMetric={handleSearchMetric} />
    )
  }

  const renderLibraryTitle = () => {
    if(_.isEmpty(selectedLibraryType)) {
      return null;
    }

    const selectedLibrary = _.find(LIST_OF_LIBRARIES, { type: selectedLibraryType });

    return (
      <LibraryTitle
        onClickBack={handleSelectedLibraryType}
        setMetricLibraryOrder={setMetricLibraryOrder}
        metricLibraryOrder={metricLibraryOrder}
        selectedLibrary={selectedLibrary} />
    )
  }

  return (
    <div className='forge-metric-drawer'>
      <ForgeDrawer direction="right" slot="right" open={isManageMetricLibrary} tabIndex={0}>
        <LoadingSpinner isLoading={isLoading} />
        <div className='metric-drawer-wrapper'>
          <div className='metric-drawer-head'>
            <div className='d-flex align-items-center gap-10'>
              <div className='forge-typography--title title'>Metric library</div>
              {renderMetricInfo()}
              <ForgeBadge>Beta</ForgeBadge>
            </div>
            <div className='cursor-pointer mt-2' onClick={() => onHandleMetricLibrary(false)}>
              <ForgeIcon name="close"></ForgeIcon>
            </div>
          </div>
          <ForgeDivider/>
          {renderSearchMetric()}
          {renderLibraryTitle()}
          {renderManageLibrary()}
        </div>
      </ForgeDrawer>
    </div>
  )
}

MetricDrawer.propTypes = {
  metricLibraryOption: PropTypes.object,
  placeCardIntoCollection: PropTypes.func,
}

export default MetricDrawer
