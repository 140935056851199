import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import SnapshotVisualization from 'modules/visualization/SnapshotVisualization';
import Table from 'modules/DetailsTable/Table';
import Map from 'modules/Map/Map';
import {
  getCurrentTemplateEntry,
} from 'common/config/templateConfiguration';

import {
  VISUALIZATION_TYPES,
  SNAPSHOT_VISUALIZATION_TYPES
} from 'appConstants';
import { getVisualizationData, getInspectViewData } from 'common/api/drilldown';
import classNames from 'classnames';
import { getDefaultSortOptionForTable } from 'modules/DetailsTable/TableHelper';
import { isShowDistributionChart } from 'helpers/templateHelper';
import DistributionChart from 'modules/visualization/DistributionChart';
import LineChart from 'modules/visualization/LineChart';
import ErrorHandler from 'common/components/NoDataFound/ErrorHandler';
import { fetchApiDataWithStatus } from 'helpers/apiResponseHelper';
import { updateCenterAndZoom, updateLegends } from 'actions/mapOptionsActions';
import { handleOnAfterPlot } from 'modules/visualization/SnapshotVisualization/snapshotAfterPlotHelper';

import {
  getQueryParams,
  getSnapShotChartAttributes,
  getCustomColumnEntries,
  getMapAttributes,
  getOvertimeAttributes,
  getDistributionAttributes,
  getTimeOfDayUrlBasedOnProps
 } from './templateCardHelper';
import { updateCardLoadingStatusForImageGenerator } from 'helpers/visualizationHelper';
import MapControls from 'modules/Map/partials/MapControls';
import RecenterButton from 'modules/Map/partials/RecenterButton';
import { VIEW_MODE } from 'modules/visualization/constants';
import NoDataFound from 'common/components/NoDataFound/NoDataFound';
import TimeOfDayOrDayOfWeek from 'modules/visualization/TimeOfDayOrDayOfWeek';


const BAR_CHART_API_LIMIT = 6;
const BAR_CHART_LIMIT = BAR_CHART_API_LIMIT - 1;

const propTypes = {
  currentVizChartType: PropTypes.string,
  visualizationEntry: PropTypes.object,
  isBookmarkCard: PropTypes.bool,
  isEmbed: PropTypes.bool,

  visualizationType: PropTypes.string,
  viewEntry: PropTypes.object,
  cardEntry: PropTypes.object,
  drilldownEntry: PropTypes.object,
  mapOptions: PropTypes.object,
  commonFilters: PropTypes.object,
  isChartAndTotalLoading: PropTypes.bool,
  showChartValues: PropTypes.bool,
  onDataLoading: PropTypes.func,
  currentDimensionTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cardImageId: PropTypes.string,
  showLegendTotalLine: PropTypes.bool,
  isHoverOverDisabled: PropTypes.bool
};

const defaultProps = {
  mapOptions: {},
  commonFilters: {},
  isChartAndTotalLoading: false,
  isBookmarkCard: false,
  onDataLoading: _.noop,
  isEmbed: false,
  showChartValues: false,
  isHoverOverDisabled: false
};

class TemplateCardVisualization extends PureComponent {
  constructor(props) {
    super(props);
    this.abortFetchController = new AbortController();
    this.visualizationDataController = new AbortController();
  }

  state = {
    visualizationData: [],
    isLoading: false,
    tableDefaultSort: {},
    totalEntryCount: null,
    currentPeriodMap: null
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { commonFilters, drilldownEntry } = this.props;
    const isDrilldownEntryChanged = !_.isEqual(
      prevProps.drilldownEntry,
      drilldownEntry
    );
    const isCommonFiltersChanged = !_.isEqual(
      commonFilters,
      prevProps.commonFilters
    );

    if (isCommonFiltersChanged || isDrilldownEntryChanged) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.abortFetchController.abort();
    this.visualizationDataController.abort();
  }

  fetchData() {
    const { drilldownEntry, viewEntry, visualizationType } = this.props;
    const queryParams = getQueryParams(this.props);

    if (VISUALIZATION_TYPES.TABLE.type === visualizationType) {
      const defaultSort = getDefaultSortOptionForTable(
        viewEntry,
        _.get(drilldownEntry, 'currentDrilldownTemplateId', '')
      );
      this.setState({ tableDefaultSort: defaultSort });
      this.fetchTableData(queryParams, defaultSort);
    } else if (
      VISUALIZATION_TYPES.DISTRIBUTION.type === visualizationType &&
      isShowDistributionChart(viewEntry)
    ) {
      return null;
    } else if (VISUALIZATION_TYPES.OVERTIME.type === visualizationType) {
      return null;
    } else if (visualizationType === VISUALIZATION_TYPES.MAP.type) {
      return null;
    } else {
      this.fetchBarChartData(queryParams);
    }
  }

  onApiDataLoad = (rowCount) => {
    this.setState({ totalEntryCount: rowCount });
  };

  // TODO remove this function after scatter chart cleanup.
  fetchBarChartData = async (queryParams) => {
    this.setState({ isLoading: true });

    this.visualizationDataController.abort();
    this.visualizationDataController = new AbortController();
    const getVisualizationDataApiUrl = getVisualizationData(queryParams);
    const response = await fetchApiDataWithStatus(
      getVisualizationDataApiUrl,
      this.visualizationDataController
    );

    const visualizationEntries = this.getVisualizationEntriesByfilter(
      response.entries
    );
    this.setState({
      visualizationData: visualizationEntries,
      isLoading: false,
      totalEntryCount: _.size(response.entries),
    });
  };

  getVisualizationEntriesByfilter = (responseEntries) => {
    let visualizationFilterValue;
    if (_.isArray(responseEntries)) {
      visualizationFilterValue = _.take(responseEntries, BAR_CHART_LIMIT);
    } else {
      const entryKeys = _.take(_.keys(responseEntries), BAR_CHART_LIMIT);
      visualizationFilterValue = _.pick(responseEntries, entryKeys);
    }
    return visualizationFilterValue;
  };

  fetchTableData = async (queryParams, defaultSort) => {
    const sortColumns = _.get(
      this.props.visualizationEntry,
      'table.sortColumns',
      []
    );
    const apiParams = _.merge(queryParams, {
      offset: queryParams.page,
      limit: 15,
      sortColumns: JSON.stringify(sortColumns.concat(defaultSort)),
    });

    const visualizationsDataApiUrl = getInspectViewData(apiParams);
    this.visualizationDataController.abort();
    this.visualizationDataController = new AbortController();

    this.setState({ isLoading: true });
    const response = await fetchApiDataWithStatus(
      visualizationsDataApiUrl,
      this.visualizationDataController
    );
    this.setState({
      visualizationData: _.get(response, 'api_data.data', []),
      isValid: response.isValid,
      statusCode: response.statusCode,
      isLoading: false,
    });
  };

  onCurrentPeriodMapCreated = (map) => {
    if(_.isEmpty(this.state.currentPeriodMap)) {
      this.setState({ currentPeriodMap: map });
    }
  };

  renderMap() {
    let mapAttributes = getMapAttributes(this.props);

    if (_.isEmpty(mapAttributes)) {
      return null;
    }
    const { onDataLoading, drilldownEntry } = this.props;
    const { currentDrilldownTemplateId } = drilldownEntry;
    const { center, zoom } = mapAttributes;
    const reCenterButtonAttributes = {
      center,
      zoom,
      templateId: currentDrilldownTemplateId,
      map: this.state.currentPeriodMap,
      viewMode: VIEW_MODE.SMALL
    };

    mapAttributes = _.merge({}, mapAttributes, {
      viewMode: VIEW_MODE.SMALL
    })
    return (
      <div className="comparison-map">
        <MapControls>
          <RecenterButton {...reCenterButtonAttributes} />
        </MapControls>
        <Map {...mapAttributes} onDataLoad={onDataLoading} onMapCreated={this.onCurrentPeriodMapCreated} />
      </div>
    )
  }

  renderSpinner() {
    return (
      <div className="spinner-overlay">
        <Spinner
          variant="primary"
          className="loading-spinner"
          animation="border"
        />
      </div>
    );
  }

  handleSetFocus = (currentContainerRef) => {
    if (currentContainerRef && currentContainerRef.contains(document.activeElement)) {
      document.activeElement.blur();
    }
  }

  handleUpdateLoadingStatus = (loadingStatus) => {
    const { cardImageId } = this.props;
    if(!_.isEmpty(cardImageId)){
      updateCardLoadingStatusForImageGenerator(cardImageId, loadingStatus);
    }
  }

  handleOnAfterPlotCard = (currentContainerRef) => {
    const { viewEntry, cardImageId } = this.props;
    const options = {
      viewEntry,
      cardImageId,
      viewMode : VIEW_MODE.SMALL
    }
    this.handleSetFocus(currentContainerRef);
    handleOnAfterPlot(currentContainerRef, options);
  }

  renderSnapShotVisualization() {
    const { isEmbed } = this.props;
    const { totalEntryCount } = this.state;
    let snapShotChartAttributes = getSnapShotChartAttributes(this.props);
    const defaultChartView = snapShotChartAttributes.defaultChartView;

    snapShotChartAttributes = _.merge(snapShotChartAttributes, {
      onApiDataLoad: this.onApiDataLoad
    });

    let chartAreaHeight,
      currentChartAttributes = {};

    switch (totalEntryCount) {
      case 1:
        chartAreaHeight = '120px';
        break;
      case 2:
        chartAreaHeight = '150px';
        break;
      case 3:
        chartAreaHeight = '180px';
        break;
      case 4:
        chartAreaHeight = '230px';
        break;
    }
    if (isEmbed) {
      chartAreaHeight = '';
    }

    const seeMoreClass = classNames({
      'chart-more-options': totalEntryCount > 5,
      'custom-style': totalEntryCount < 5 || totalEntryCount >= 1,
    });

    if (
      _.isEqual(defaultChartView, SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type)
    ) {
      return <SnapshotVisualization {...snapShotChartAttributes.pieChartAttributes}
        onAfterPlot= {this.handleOnAfterPlotCard} />;
    } else if (
      _.isEqual(
        defaultChartView,
        SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type
      )
    ) {
      currentChartAttributes = snapShotChartAttributes.scatterChartAttributes;
    } else {
      currentChartAttributes = snapShotChartAttributes.barAttributes;
    }

    const snapshotView = _.isEmpty(currentChartAttributes.currentSnapshotView);

    return (
      <div className={seeMoreClass} style={{ height: chartAreaHeight }}>
        { snapshotView && <NoDataFound  onUpdateLoadingStatus={this.handleUpdateLoadingStatus}
          viewMode = {currentChartAttributes.viewMode} /> }
        { !snapshotView && <SnapshotVisualization
          {...currentChartAttributes} onAfterPlot={this.handleOnAfterPlotCard} /> }
        <div className="clearfix"></div>
      </div>
    );
  }

  renderOvertimeVisualization() {
    const overtimeAttributes = getOvertimeAttributes(this.props);
    const { isEmbed, isBookmarkCard, showLegendTotalLine } = this.props;

    return (
      <LineChart
        isEmbed={isEmbed}
        key={overtimeAttributes.templateCardKey}
        isCurrencyDimensionField={overtimeAttributes.isCurrencyDimensionField}
        axisGranularity={overtimeAttributes.axisGranularity}
        apiParams={overtimeAttributes.apiParams}
        dateRangeMode={overtimeAttributes.dateRangeMode}
        viewEntry={overtimeAttributes.viewEntry}
        viewMode={overtimeAttributes.viewMode}
        benchMarkEntries={overtimeAttributes.benchMarkEntries}
        onDataLoading={this.props.onDataLoading}
        isChartAndTotalLoading={overtimeAttributes.isChartAndTotalLoading}
        isComboChart={overtimeAttributes.isComboChart}
        templateId={overtimeAttributes.templateId}
        secondaryMetricEntry={overtimeAttributes.secondaryMetricEntry}
        sliderRange={overtimeAttributes.sliderRange}
        renderType={overtimeAttributes.chartType}
        renderTimeFrame={overtimeAttributes.renderTimeFrame}
        compareYearRanges={overtimeAttributes.compareYearRanges}
        dateRange={overtimeAttributes.dateRange}
        compareToRanges={overtimeAttributes.compareToRanges}
        projectionEnabled={overtimeAttributes.projectionEnabled}
        render={(chart) => chart}
        projectionType={overtimeAttributes.projectionType}
        dimensionConfigsByRenderType={overtimeAttributes.dimensionConfigsByRenderType}
        isDimensionHighToLow = {overtimeAttributes.isDimensionHighToLow}
        cardImageId={overtimeAttributes.cardImageId}
        isBookMark={isBookmarkCard}
        showLegendTotalLine={showLegendTotalLine}
        isHoverOverDisabled={overtimeAttributes.isHoverOverDisabled}
      />
    );
  }

  renderTableVisualization() {
    const { drilldownEntry, visualizationEntry, onDataLoading, cardImageId } = this.props;
    const { currentDrilldownTemplateId } = drilldownEntry;
    const { visualizationData, tableDefaultSort, isLoading, isValid, statusCode } = this.state;
    const sortColumns = _.get(visualizationEntry, 'table.sortColumns', []);
    const isNodataFound = _.isEmpty(visualizationData) && !isLoading;

    if (isNodataFound || !isValid) {
      return <ErrorHandler isNodataFound={isNodataFound}
        statusCode={statusCode} onUpdateLoadingStatus={this.handleUpdateLoadingStatus} />;
    }

    return (
      <Table
        templateEntry={getCurrentTemplateEntry(currentDrilldownTemplateId)}
        tableData={visualizationData}
        apiParams={getQueryParams(this.props)}
        sortColumns={sortColumns}
        onDataLoad={onDataLoading}
        customColumnEntries={getCustomColumnEntries(this.props)}
        tableDefaultSort={tableDefaultSort}
        cardImageId={cardImageId}
      />
    );
  }

  renderDistributionVisualization() {
    const distributionAttributes = getDistributionAttributes(this.props);
    const { onDataLoading, isEmbed } = this.props;

    return (
      <DistributionChart
        isEmbed={isEmbed}
        apiParams={distributionAttributes.apiParams}
        isCurrencyDimensionField={distributionAttributes.isCurrencyDimensionField}
        viewEntry={distributionAttributes.viewEntry}
        quickFilters={distributionAttributes.quickFilters}
        dimensionColumn={distributionAttributes.dimensionColumn}
        viewMode={distributionAttributes.viewMode}
        distributionOption={distributionAttributes.defaultDistributionOptions}
        isChartAndTotalLoading={distributionAttributes.isChartAndTotalLoading}
        onDataLoading={onDataLoading}
        templateId={distributionAttributes.templateId}
        benchmarkMetricNames={distributionAttributes.benchmarkMetricNames}
        isHoverOverDisabled={distributionAttributes.isHoverOverDisabled}
      />
    );
  }

  renderTimeOfDayVisualization = () => {
    const { drilldownEntry, mapOptions, commonFilters, cardEntry } = this.props;
    const url = getTimeOfDayUrlBasedOnProps({ drilldownEntry, mapOptions, commonFilters, cardEntry });
    return(
      <TimeOfDayOrDayOfWeek url={url} weekField="short_x"
        viewEntry={_.get(drilldownEntry, 'currentDrilldownViewEntry', {})}
      />
    );
  }

  render() {
    const { visualizationType, viewEntry, isChartAndTotalLoading } = this.props;
    const { isLoading } = this.state;

    if (isLoading || isChartAndTotalLoading) {
      return this.renderSpinner();
    }

    switch (visualizationType) {
      case VISUALIZATION_TYPES.SNAPSHOT.type:
        return this.renderSnapShotVisualization();
      case VISUALIZATION_TYPES.MAP.type:
        return this.renderMap();
      case VISUALIZATION_TYPES.OVERTIME.type:
        return this.renderOvertimeVisualization();
      case VISUALIZATION_TYPES.TABLE.type:
        return this.renderTableVisualization();
      case VISUALIZATION_TYPES.TIME_OF_DAY.type:
          return this.renderTimeOfDayVisualization();
      case VISUALIZATION_TYPES.DISTRIBUTION.type:
        if (isShowDistributionChart(viewEntry)) {
          return this.renderDistributionVisualization();
        } else {
          return this.renderSnapShotVisualization();
        }
      default:
        console.error(`Visualization Type (${visualizationType}) not found.`);
        return this.renderSnapShotVisualization();
    }
  }
}

TemplateCardVisualization.propTypes = propTypes;
TemplateCardVisualization.defaultProps = defaultProps;

const mapDispatchToProps = {
  dispatchUpdateCenterAndZoom: updateCenterAndZoom,
  dispatchUpdateLegends: updateLegends,
};

export default connect(null, mapDispatchToProps)(TemplateCardVisualization);
