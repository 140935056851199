import React from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import moment from "moment";

import { ForgeIcon } from "@tylertech/forge-react";
import { updateStoreToUrl } from "helpers/UrlParamsHelper";
// import MetricTotal from "./MetricTotal";
import MetricVisualization from "./MetricVisualization";

const ModalBody = ({ metricCardEntry }) => {
  const commonFilters = useSelector(state => _.get(state, 'commonFilters', {}));
  const { viewEntry, userCardEntry } = metricCardEntry;
  const {
    bookmarkOptions,
    isBookmarkCard,
    created_at,
    viewEntry: bookmarkViewEntry,
    drilldown,
  } = userCardEntry;

  const viewName = isBookmarkCard ? _.get(userCardEntry, 'name', '') : _.get(viewEntry, 'name', '');
  const cardDescription = isBookmarkCard ?
    _.get(bookmarkOptions, 'description', '') : _.get(viewEntry, 'view_description', '');

  // const renderTotal = () => {
  //   return(
  //     <div className="metric-total">
  //       <MetricTotal metricCardEntry={metricCardEntry} />
  //     </div>
  //   );
  // }

  const renderVisualization = () => {
    return(
      <div className="chart-visualization">
        <div className="chart-visualization-icon-wrapper">
          <MetricVisualization metricCardEntry={metricCardEntry} />
        </div>
      </div>
    );
  }

  const savedViewLink = () => {
    const stateWithFilters = {
      commonFilters,
      drilldown
    };
    return updateStoreToUrl('/#!/analysis', stateWithFilters);
  }

  const savedViewContent = () => {
    const createdAt = moment(created_at).format('MMMM DD, YYYY');
    const linkText = bookmarkViewEntry ? bookmarkViewEntry.name : 'Bookmark';
    if (!isBookmarkCard) {
      return null;
    }
    return (
      <div>
        <div class="save-view">
          <i class="icons-bookmark-manager mr-2"></i>
          <span class="view-metric-link-container forge-typography--caption">
            Saved view based on
            <a href={savedViewLink()} target="_blank" rel="noreferrer"> {linkText} </a>
          </span>
        </div>
        <div class="created-at mt-2">
          <ForgeIcon name='mode_edit' className='create-icon mr-2' />
          <span class="create-at-container forge-typography--caption">
            Created {createdAt}
          </span>
        </div>
      </div>
    );
  }

  return (
    <section className="forge-dialog__body">
      <div className='quick-view-modal-body'>
        <div className='metric-card-title forge-typography--subtitle1-secondary'>{viewName}</div>
        <div className='metric-card-description forge-typography--caption'>{cardDescription}</div>
      </div>
      {/* {renderTotal()} */}
      {renderVisualization()}
      {savedViewContent()}
    </section>
  );
}

ModalBody.propTypes = {
  metricCardEntry: PropTypes.object.isRequired

}

export default ModalBody;
