import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeButton, ForgeMenu } from '@tylertech/forge-react';

class ForgeMenuOptions extends Component {

  render() {
    const {
      menuOptions, menuLabelName, customIconName , onSelectOption, customLabelIcon, buttonType
    } = this.props;


    return (
      <ForgeMenu tabIndex={0}
        open={false}
        placement='bottom'
        dense={false}
        persistSelection={false}
        options={menuOptions}
        on-forge-menu-select={onSelectOption}>
        <ForgeButton type={buttonType} className="map-forge-btn">
          <button type="button">
            {customIconName && <i className={customIconName}></i>}
            {customLabelIcon && <div>{customLabelIcon}</div>}
            <span>{menuLabelName}</span>
            <ForgeIcon name='keyboard_arrow_down' className="mr-0" /></button>
        </ForgeButton>
      </ForgeMenu>
    )
  }
}

ForgeMenuOptions.defaultProps = {
  customIconName: '',
  className: '',
  menuOptions:[],
  buttonType: 'outlined'
};

ForgeMenuOptions.propTypes = {
  menuLabelName:PropTypes.string,
  trailingIconName: PropTypes.string,
  customIconName: PropTypes.string,
  onSelectOption: PropTypes.func,
  menuOptions: PropTypes.array,
  selectIndex: PropTypes.number,
  customLabelIcon: PropTypes.string,
  buttonType: PropTypes.string,
};

export default ForgeMenuOptions;
