import { deleteBookmark } from "common/api/bookmarksApi";
import { useState } from "react";

export const useDeleteBookmarkCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const deleteBookmarkCard = async (bookmarkId) => {
    setIsLoading(true);
    try {
      const response = await deleteBookmark(bookmarkId);
      if (!response.ok) {
        throw new Error("Failed to delete bookmark");
      }
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }

  return { deleteBookmarkCard, isLoading, error, success };
};
