import React from 'react';
import { ForgeDivider, ForgeIcon } from '@tylertech/forge-react';
import ForgeMenuOptions from 'common/components/MenuDropDown/ForgeMenuOptions';
import PropTypes from 'prop-types';
import { getLibraryOrderOptions } from './metricLibraryHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
function LibraryTitle({ selectedLibrary, onClickBack, setMetricLibraryOrder, metricLibraryOrder }) {
  const { name } = selectedLibrary;

  const bookmarkOrderDropdown = () => {
    return (
    <ForgeMenuOptions
      menuLabelName={metricLibraryOrder}
      menuOptions={getLibraryOrderOptions(selectedLibrary, metricLibraryOrder)}
      buttonType='flat'
      onSelectOption={onChangeSort}
    />);
  }

  const onChangeSort = (sortOption) => {
    setMetricLibraryOrder(_.get(sortOption, 'detail.value'));
  }

  const onEnterKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      onClickBack('');
    }
  }

  return (
    <div className='cursor-pointer'>
      <ForgeDivider />
      <div className='cursor-pointer d-flex align-items-center justify-content-between gap-10 px-2 my-2'>
        <span className='d-flex align-items-center gap-10' tabIndex={0}
          onClick={() => onClickBack('')}
          onkeydown={(e) => onEnterKeyDown(e)}>
          <ForgeIcon  name="arrow_back"/>
          {name}
        </span>
          {bookmarkOrderDropdown()}
      </div>
      <ForgeDivider />
    </div>
  )
}

LibraryTitle.propTypes = {
  selectedLibrary: PropTypes.object,
  onClickBack: PropTypes.func,
  setMetricLibraryOrder: PropTypes.func,
  metricLibraryOrder: PropTypes.string
};

export default LibraryTitle;
