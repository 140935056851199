import React from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import { LIST_OF_LIBRARIES } from 'appConstants';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

function ManageMetricLibrary({ onSelectLibraryType }) {

  const onEnterClick = (e, libraryType) => {
    if(isEnterButtonPressed(e)){
      onSelectLibraryType(libraryType);
    }
  }

  const renderLibraryListItem = (library) => {
    return (
      <ForgeIconButton disabled={library.disabled} tabIndex={0} key={library.type}
        onkeydown={(e) => onEnterClick(e, library.type)}
        onClick={() => onSelectLibraryType(library.type)}>
        <div className="d-flex align-items-center justify-content-between cursor-pointer">
          <div className='d-flex align-items-center gap-20'>
            <ForgeIcon name={library.leadingIcon} />
            <span>{library.name}</span>
          </div>
          <ForgeIcon name={library.trailingIcon} />
        </div>
      </ForgeIconButton>
    );
  };

  const renderLibraryListItems = () => {
    return _.map(LIST_OF_LIBRARIES, (library) => renderLibraryListItem(library));
  };

  return <div className='manage-library p-3 mt-2'>{renderLibraryListItems()}</div>;
}

ManageMetricLibrary.propTypes = {
  onSelectLibraryType: PropTypes.func,
};

export default ManageMetricLibrary;
