import { useState } from "react";

const useDragCheck = () => {
  const [position, setPosition] = useState({ x: 100, y: 100 });
  const [isDragging, setIsDragging] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [canCallClickEvent, setCanCallClickEvent] = useState(false);

  let startX, startY, offsetX, offsetY;

  const onMouseDown = (e, options) => {
    setIsDragging(false);
    setEventData(options);
    startX = e.clientX;
    startY = e.clientY;
    offsetX = e.clientX - position.x;
    offsetY = e.clientY - position.y;

    const onMouseMove = (e) => {
      if (Math.abs(e.clientX - startX) > 5 || Math.abs(e.clientY - startY) > 5) {
        setIsDragging(true);
        setPosition({
          x: e.clientX - offsetX,
          y: e.clientY - offsetY,
        });
      }
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);

      if (!isDragging) {
        setCanCallClickEvent(true);
      }
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  return { onMouseDown, canCallClickEvent, setCanCallClickEvent, eventData };
};

export default useDragCheck;
