import React from 'react'
import PropTypes from 'prop-types'
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react'
import { useSelector } from 'react-redux';
import { updateMetricOpenedAt } from 'common/api/metricTotal';
import { updateStoreToUrl } from 'helpers/UrlParamsHelper';

function ViewButton({ selectedMetric, toggleModalOpen }) {
  const commonFilters = useSelector(state => _.get(state, 'commonFilters', {}));
  const drilldown =  useSelector(state => _.get(state, 'drilldown', {}))
  const visualization =  useSelector(state => _.get(state, 'visualization', {}))
  const currentBookmark = useSelector(state => _.get(state, 'bookmark', {}));
  const { templateEntry, viewEntry, userCardEntry } = selectedMetric;
  const { isBookmarkCard } = userCardEntry;

  const onViewClick = () => {
    const params = {
      template_id: templateEntry.template_id,
      view_id: viewEntry.view_id
    }
    updateMetricOpenedAt(params);

    const stateWithFilters = {
      commonFilters,
      drilldown,
      visualization
    };

    if(isBookmarkCard){
      stateWithFilters.bookmark = currentBookmark;
    }
    const href = updateStoreToUrl('/#!/analysis', stateWithFilters);
    window.open(href, '_blank');
    toggleModalOpen(false);
  }

  return (
    <ForgeButton type="outlined" className='mr-2'>
      <button type="button" aria-label="View"
        onClick={() => onViewClick()}>
        <span>View</span>
        <ForgeIcon name="open_in_new" tabIndex={0}/>
      </button>
    </ForgeButton>
  );
}

ViewButton.propTypes = {
  selectedMetric: PropTypes.object.isRequired,
  toggleModalOpen: PropTypes.func
}

export default ViewButton
