import _ from 'lodash';
import React, {useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

import PlotlyCustomTooltip from 'modules/PlotlyTooltip';
import TimeOfDayTipsyTable from './TimeOfDayTipsyTable';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

import { getCellColor } from 'modules/utils/timeOfDayOrDayOfWeekHelper';
import { getArrowPosition, setZIndexForCollectionTabs } from 'helpers/DomPageHelper';
const DEBOUNCE_WAIT_TIME = 150;

const TimeOfDayTableColumn = ({
  weekOption, dayOption, index, colorBuckets, weekWiseData, defaultColor, selectedColor,
  selectedCells, onColumnClick, viewEntry
}) => {
  const popupContainerRef = useRef();
  const columnRef = useRef();
  const [tooltip, setTooltip] = useState(null);

  const isFirstIndex = index == 0;
  const columnName = isFirstIndex ? weekOption.name : '';
  const columnClassName = isFirstIndex ? 'week-title' : 'item-cell';
  const { color: cellColor, total } = getCellColor({
    weekWiseData, colorBuckets, weekOption, dayOption, defaultColor, selectedColor, selectedCells
  });

  useEffect(() => {
    const tooltipObject = new PlotlyCustomTooltip(popupContainerRef.current);
    setTooltip(tooltipObject)
  }, []);

  const onColumnMouseEnter = (e) => {
    if (isFirstIndex) { return }
    const columnBoundingClientRect = columnRef.current.getBoundingClientRect();
    const tipsyAttributes = { weekOption, dayOption, weekWiseData, viewEntry };
    const popupConfigs =  [{
      position: getArrowPosition(e),
      isTimelineChart: true,
      isTimeOfDayChart: true,
      anchor: { x: (e.clientX - columnBoundingClientRect.x), y: (e.clientY - columnBoundingClientRect.y) },
      chartContainer: columnRef.current,
      html: (<TimeOfDayTipsyTable {...tipsyAttributes} />)
    }];
    // setZIndexForCollectionTabs(0);
    if(total) {
      tooltip.showPopups(popupConfigs);
    }
  }

  const onColumnMouseLeave = () => {
    setZIndexForCollectionTabs(10006);
    tooltip.hidePopups();
  }
  const debouncedMouseEnter = _.debounce(onColumnMouseEnter, DEBOUNCE_WAIT_TIME, { trailing: true });
  const debouncedMouseLeave = _.debounce(onColumnMouseLeave, DEBOUNCE_WAIT_TIME, { trailing: true });
  const columnOption = { day: weekOption.day, hour: dayOption.hour };
  const isEntrySelected = _.find(selectedCells, (selectedCellEntry) => {
    return _.isEqual(selectedCellEntry, columnOption);
  });
  const attributes = {
    className: classNames('cell', {'selected': isEntrySelected }),
    style: {backgroundColor: cellColor },
    dragselect: JSON.stringify(columnOption),
    tabIndex: 0,
    onClick: () => {
      if(selectedColor) {
        onColumnClick(columnOption)
      }
    },
    onKeyDown: (e) => {
      if(isEnterButtonPressed(e)){
        if(selectedColor) {
          onColumnClick(columnOption)
        }
      }
    }
  }

  return(
    <td className='time-of-day-column' key={`${weekOption.id}-${index}`} ref={columnRef}>
      <div className={columnClassName} onMouseEnter={debouncedMouseEnter} onMouseLeave={debouncedMouseLeave}>
        {columnName}
        {!isFirstIndex && <div {...attributes}></div>
        }
      </div>
      <div className="popup-container" ref={popupContainerRef}></div>
    </td>
  );
}

TimeOfDayTableColumn.propTypes = {
  weekOption: PropTypes.exact({
    id: PropTypes.string,
    short: PropTypes.string,
    short_x: PropTypes.string,
    medium: PropTypes.string,
    long: PropTypes.string,
    name: PropTypes.string,
    day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  dayOption: PropTypes.exact({
    id: PropTypes.string,
    short: PropTypes.string,
    long: PropTypes.string,
    hour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  colorBuckets: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
      label: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  index: PropTypes.number,
  defaultColor: PropTypes.string,
  selectedColor: PropTypes.string,
  selectedCells: PropTypes.array,
  weekWiseData: PropTypes.object,
  viewEntry: PropTypes.object,
  onColumnClick: PropTypes.func
}

export default TimeOfDayTableColumn;
