import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import EmbedBookmark from 'pages/dashboard/components/BookmarkCards/EmbedBookmark';
import DeleteConfirmModal from 'pages/dashboard/components/DeleteConfirmModal';
import {
  ForgeIcon,
  ForgeMenu,
  ForgeIconButton
} from '@tylertech/forge-react';
import {
  MY_VIEW_BUTTON_BUTTON,
  MY_VIEW_DELETE_TITLE,
  VIEW_DELETE_MESSAGE,
} from 'appConstants';
import { getCardId } from 'pages/embed/helper';
import { shouldShowEmbedLinkInKabob } from 'common/config/customerConfiguration';
import { updateStoreToUrl } from 'helpers/UrlParamsHelper';
import { getBookmarkType } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import { useDeleteBookmarkCard } from '../../useDeleteBoomarkCard';
import { viewDeleteErrorMessage, viewDeleteMessage } from 'helpers/toastMessages';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

function SavedViewHeader(props) {
  const {
    metricCardEntry,
    toggleModalOpen,
    headerTitle,
    setIsCardsLoading,
    onDeleteMetricCard
  } = props;

  const deleteMessage = VIEW_DELETE_MESSAGE;
  const buttonLabel = MY_VIEW_BUTTON_BUTTON;
  const modalTitle = MY_VIEW_DELETE_TITLE

  const { templateEntry, viewEntry, userCardEntry } = metricCardEntry;
  const cardId = getCardId(templateEntry, viewEntry);
  const currentBookmarkId = userCardEntry.id;

  const [showEmbedConfirmModal, setShowEmbedConfirmModal] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const dispatch = useDispatch();

  const {
    deleteBookmarkCard,
    isLoading,
    success: isBookmarkDeleted,
    error: bookmarkDeleteError
  } = useDeleteBookmarkCard();

  useEffect(() => {
    setIsCardsLoading(isLoading);
    if(isBookmarkDeleted){
      onDeleteMetricCard(currentBookmarkId);
      toast.success(viewDeleteMessage);
    }
    if(!_.isNull(bookmarkDeleteError)){
      toast.error(viewDeleteErrorMessage);
    }
  }, [isLoading, isBookmarkDeleted, bookmarkDeleteError]);

  const handleCardDelete = async () => {
    await deleteBookmarkCard(currentBookmarkId);
  };

  const deleteConfirmationModal = () => {
    return(
      <DeleteConfirmModal
        title={modalTitle}
        message={deleteMessage}
        showNormalButton={true}
        showButtons={false}
        confirmButtonName={buttonLabel}
        onCancelConfirm={() => setIsDeleteClicked(false)}
        onDeleteConfirm={handleCardDelete}
      />
   );
  }

  const renderEmbedModel = () => {
    if(!showEmbedConfirmModal){
      return null;
    }

    return (
      <EmbedBookmark
        bookmark={userCardEntry}
        onModalClose={handleEmbedClick} />
    );
  }

  const handleEmbedClick = () => {
    setShowEmbedConfirmModal(false);
  };

  const onEditBookmark = () =>{
    const { id, name } = userCardEntry;
    const bookmarkType = getBookmarkType(userCardEntry);
    dispatch(updateCurrentBookmark(id + '', name, bookmarkType));
    const bookmarkState = {
      currentBookmarkId: id,
      currentBookmarkType: bookmarkType
    }
    const href = updateStoreToUrl('/#!/app/subscriptions', {bookmark: bookmarkState});
    window.open(href, '_blank');
    toggleModalOpen(false);
  }

  const handleMenuSelect = ({ detail }) => {
    const { value } = detail;
    if (value === 'embed') {
      setShowEmbedConfirmModal(true)
    } else if (value === 'edit') {
      onEditBookmark();
    } else if (value === 'delete') {
      setIsDeleteClicked(true);
    }
  };

  const renderMenus = () => {
    const showEmbedLinkInKabob = shouldShowEmbedLinkInKabob();

    let options = [
      { value: 'edit', label: 'Edit' },
      { value: 'delete', label: 'Delete' }
    ];

    if (showEmbedLinkInKabob) {
      options.push({ 'value': 'embed', label: 'Embed' });
    }

    return (
      <ForgeMenu
        placement='bottom-start'
        key={'library-card' + cardId}
        options={options}
        on-forge-menu-select={handleMenuSelect}>
        <ForgeIconButton type="button">
          <button type="button" aria-label="Card menu">
            <ForgeIcon name='more_horiz' className="more-icon" />
          </button>
        </ForgeIconButton>
      </ForgeMenu>
    );
  }

  return (
    <div>
      <header className="forge-dialog__header">
        <div className='forge-typography--caption'>{headerTitle}</div>
        <span className="cursor-pointer more-info forge-popup-host">
          { renderMenus() }
        </span>
      </header>
      {showEmbedConfirmModal && renderEmbedModel()}
      {isDeleteClicked && deleteConfirmationModal()}
    </div>
  );
}

SavedViewHeader.propTypes = {
  metricCardEntry: PropTypes.object.isRequired,
  toggleModalOpen: PropTypes.func,
  headerTitle: PropTypes.string,
  setIsCardsLoading: PropTypes.func,
  onDeleteMetricCard: PropTypes.func
}

export default SavedViewHeader
