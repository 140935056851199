import React from 'react';
import PropTypes from 'prop-types';

import CustomButton from 'common/components/CustomButton';
import ViewButton from './ViewButton';

const ModalFooter = (props) => {
  const { toggleModalOpen, metricCardEntry, onPlaceClick } = props;

  const handlePlaceButtonClick = (e) => {
    e.stopPropagation();
    toggleModalOpen(false);
    onPlaceClick();
  }
  const placeButton = () => {
    if (metricCardEntry.isCardExist) {
      return null;
    }
    return (
      <CustomButton onClick={(e) => handlePlaceButtonClick(e)} label="Place" />
    );
  }

  const viewButton = () => {
    return(
      <ViewButton selectedMetric={metricCardEntry} toggleModalOpen={toggleModalOpen}/>
    );
  }

  const closeButton = () => {
    return (
      <CustomButton onClick={() => toggleModalOpen(false)} label="Close" />
    );
  }

  return (
    <footer className="forge-dialog__footer">
      <div className='place-button'>{placeButton()}</div>
      <div className='explore-buttons'>
        {viewButton()}
        {closeButton()}
      </div>
    </footer>
  );
}

ModalFooter.propTypes = {
  toggleModalOpen: PropTypes.func,
  onPlaceClick: PropTypes.func,
  metricCardEntry: PropTypes.object,
}

export default ModalFooter;
