import React from "react";

const AccessDenied = () => {
  return (
    <div className="access-denied-wrapper">
      <img src='../images/403-error-spot-hero.svg' />
      <div className="forge-typography--headline4">
        You do not have permission to view this collection.
      </div>
      <div className="forge-typography--headline4"> Contact your site administrator for help.</div>
    </div>
  );
}

export default AccessDenied;
