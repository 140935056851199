import React from 'react';
import PropTypes from 'prop-types';
import MetricHeader from './MetricHeader';
import SavedViewHeader from './SavedViewHeader';

const ModalHeader = ({
  toggleModalOpen,
  metricCardEntry,
  onDeleteMetricCard,
  setIsCardsLoading
}) => {
  const { userCardEntry } = metricCardEntry;
  const { isBookmarkCard } = userCardEntry
  const headerTitle = 'Quick View';

  const metricHeader = () => {
    return(
      <MetricHeader
        metricCardEntry={metricCardEntry}
        toggleModalOpen={toggleModalOpen}
        headerTitle={headerTitle}
        onDeleteMetricCard={onDeleteMetricCard}
        setIsCardsLoading={setIsCardsLoading}
      />
    );
  }

  const savedViewHeader = () => {
    return(
      <SavedViewHeader metricCardEntry={metricCardEntry}
        headerTitle={headerTitle}
        onDeleteMetricCard={onDeleteMetricCard}
        setIsCardsLoading={setIsCardsLoading}
      />
    );
  }

  return (
    <>
      { isBookmarkCard ?  savedViewHeader() : metricHeader()}
    </>
  );
}

ModalHeader.propTypes = {
  toggleModalOpen: PropTypes.func,
  metricCardEntry: PropTypes.object,
  onDeleteMetricCard: PropTypes.func,
  setIsCardsLoading: PropTypes.func
}

export default ModalHeader;
